import React from 'react';
import { TContextMenu } from '../../../common/types/ContextMenu';
import ContextMenu from '../../controls/ContextMenu/ContextMenu';
import { TContextAlignment } from '../../../common/types/ContextAlignment';
import CardDataMenu from './CardDataMenu';
import { CARD_DATA_LABELS } from '../../../common/configs/CardDataLabels';
import Button from '../../controls/Button/Button';
import { TCardData } from '../../../common/types/CardData';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  disabled: boolean;
  contextMenuClassName: TContextAlignment;
  setSelectedCardData: (cardData?: TCardData) => void;
  selectedCardData: TCardData[];
}

interface State {
  selectedContext: TContextMenu;
}

class CardDataContext extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedContext: 'randomizer',
    };
  }

  setSelectedContext = (context: TContextMenu) => {
    this.setState({
      selectedContext: context,
    });
  };

  restoreDefaults = () => {
    this.props.setSelectedCardData();
  };

  getCardDataTitle = (): string => {
    if (
      this.props.selectedCardData?.length !==
      Object.keys(CARD_DATA_LABELS).length
    ) {
      return this.props.t('showHideDataSomeHidden');
    } else {
      return this.props.t('showHideData');
    }
  };

  render() {
    const { t } = this.props;
    const tampered: boolean =
      this.props.selectedCardData?.length !==
      Object.keys(CARD_DATA_LABELS).length;
    const contextComponents: { [key: string]: React.ReactElement } = {
      randomizer: (
        <CardDataMenu
          setSelectedCardData={this.props.setSelectedCardData}
          selectedCardData={this.props.selectedCardData}
        />
      ),
    };

    return (
      <ContextMenu
        dept={0}
        name="customizerMenu"
        context={this.state.selectedContext}
        resetSelectedContext={() => this.setSelectedContext('randomizer')}
        contextMenuClassName={this.props.contextMenuClassName}
        triggerClassActive="secondary-button"
        triggerClassDefault={`${
          !tampered ? 'ghost-button' : 'secondary-button'
        }`}
        isDisabled={this.props.disabled}
        title={this.getCardDataTitle()}
        triggerContent={
          <>
            <span
              className={`pe-none icon fas fa-eye ${
                tampered ? 'accent-text' : ''
              }`}
            ></span>
            <span className="pe-none fas fa-angle-down"></span>
          </>
        }
      >
        <li>
          <Button
            className="secondary-button"
            onClick={this.restoreDefaults}
            disabled={!tampered}
          >
            {this.props.t('restoreDefaults')}
          </Button>
        </li>
        <li>
          <hr
            style={{
              marginLeft: '-1rem',
              marginRight: '-1rem',
              width: 'calc(100% + 2rem)',
            }}
          />
        </li>
        {contextComponents[this.state.selectedContext]}
      </ContextMenu>
    );
  }
}

export default withStyledTranslation('cardDataContext')(CardDataContext);
