import { LexicalEditor } from 'lexical';
import React, { Component } from 'react';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import { PriorityDTO } from '../../../../common/api/dtos/Priority';
import { TagDTO } from '../../../../common/api/dtos/Tag';
import { archiveCard, updateCard } from '../../../../common/api/endpoints/card';
import AppContext, {
  IAppContext,
} from '../../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../../common/contexts/NotificationsContext';
import BoardContext from '../../../../common/contexts/BoardContext';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import Button from '../../../controls/Button/Button';
import { withContextAdapters } from '../../ContextAdapter/withContextAdapter';
import CardFlyout from './CardFlyout';
import { ColumnDTO } from '../../../../common/api/dtos/Column';
import { IBoardCurrentUser } from '../../../../common/interfaces/BoardCurrentUser';
import { MsgWs } from '../../../pages/Board/BoardProvider/wsHandlers';
import { History, Location } from 'history';
import { $convertToMarkdownString } from '@lexical/markdown';
import { CUSTOM_TRANSFORMERS } from '../../../controls/LexicalControl/transformers/CustomTransformers';
import ContextMenu from '../../../controls/ContextMenu/ContextMenu';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import Board from '../../../../vendors/wavemyth/react-beautiful-dnd/test/unit/integration/util/board';
import selectTutorial from '../../../../common/helpers/selectTutorial';
import { getChecklistItems } from '../../Onboarding/Tutorial/tutorialData';
import { updateTutorialStep } from '../../../../common/helpers/tutorialHelper';
import { IClientData } from '../../../../common/interfaces/ClientData';
import {
  IBoardContext,
  UpdateCardData,
} from '../../../../common/interfaces/BoardContext';
import { TCardData } from '../../../../common/types/CardData';

interface AppContextProps {
  loggedUser: IAppContext['loggedUser'];
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
  updateClientData: (data: Partial<IClientData>) => void;
}
interface BoardContextProps {
  tags: TagDTO[];
  priorities: PriorityDTO[];
  members: (MemberDTO | InviteeDTO)[];
  boardUser: IBoardCurrentUser;

  removeCardsFromColumn: (columndId: string, cardIds: string[]) => void;
  updateCard: (cardId: string, data: UpdateCardData) => void;
  addWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  removeWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  addAttachment: (cardId: string) => void;
  removeAttachment: (cardId: string) => void;
  addComment: (cardId: string) => void;
  deleteComment: (cardId: string) => void;
  addSubtask: (cardId: string) => void;
  updateSubtask: (cardId: string, checked: boolean) => void;
  removeSubtask: (cardId: string, checked: boolean) => void;
}
interface ExternalProps {
  cardNrPrefix: string;
  boardCard: BoardCard;
  columnId: string;
  handleExpandColumn: (columnId: string) => void;
  onClose: () => void;
  columnTitles: Partial<ColumnDTO>[];
  history: History;
  location: Location;
  clipboardData: File;
  setUploadClipboardData: (value: boolean) => void;
  uploadClipboardData: boolean;
  selectedCardData: TCardData[];
}
interface Props
  extends AppContextProps,
    ExternalProps,
    BoardContextProps,
    WithTranslation {}

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  tagIds: string[];
  assigneeIds: string[];
  description: string;
  priorityId: string | null;
  numberOfAttachments: number;
  numberOfComments: number;
}

interface FormData {
  card: {
    title: string;
    description: string;
  };
  comment: string;
}

interface State {
  serverErrors: string[];
  formData: FormData;
  updatingCard: boolean;
  showAddcomment: boolean;
  descriptionEditor: LexicalEditor | null;

  // Keep track of the initial values for properties
  // which can be reverted.
  initialCard: InitialCard;
}

interface InitialCard {
  title: string;
  description: string;
  numberOfAttachments?: number;
  numberOfComments?: number;
}

class CardDetail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      serverErrors: [],
      updatingCard: false,
      showAddcomment: false,
      initialCard: {
        title: this.props.boardCard.title,
        description: this.props.boardCard.description,
        numberOfAttachments: this.props.boardCard.numberOfAttachments,
        numberOfComments: this.props.boardCard.numberOfComments,
      },
      formData: {
        comment: '',
        card: this.props.boardCard,
      },
      descriptionEditor: null,
    };
  }

  setDescriptionEditor = (editor: any) => {
    this.setState({
      descriptionEditor: editor,
    });
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevProps.boardCard !== this.props.boardCard) {
      this.initFyoutCard();
    }
  }

  componentDidMount() {
    this.initFyoutCard();
    this.props.handleExpandColumn(this.props.columnId);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyDown);

    // the card description will be saved if the flyout is closed while editing.
    // to avoid unnecessary markdown conversion, the code will run only if the editor is editable (only during editing).
    if (this.state.descriptionEditor?.isEditable()) {
      this.state.descriptionEditor &&
        this.state.descriptionEditor.getEditorState().read(() => {
          const markdown = $convertToMarkdownString(
            CUSTOM_TRANSFORMERS,
          ).replaceAll(/\n{2}/gm, '\n');

          // to prevent a description update when navigating to another page,
          // we need to check if the new location is in board detail
          if (
            this.props.history.location.pathname.includes('board') &&
            this.props.history.location.pathname.includes('view') &&
            markdown !== this.props.boardCard.description
          ) {
            this.onDescriptionChange(markdown);
          }
        });
    }
  }
  tutorialAction_updateDescription = async () => {
    const loggedUser = this.props.loggedUser;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      if (userType !== 'solo') return;
      const checklistItems = getChecklistItems(userType);
      const stepId = 'add_card_description';

      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        this.props.setMessages,
        this.props.updateClientData,
      );
    }
  };

  tutorialAction_uploadAttachment = async () => {
    const loggedUser = this.props.loggedUser;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      if (userType !== 'invited' && userType !== 'teamOwner') return;
      const checklistItems = getChecklistItems(userType);
      const stepId =
        userType === 'invited'
          ? 'upload_attachment_invited'
          : 'upload_attachment';

      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        this.props.setMessages,
        this.props.updateClientData,
      );
    }
  };

  tutorialAction_addComment = async () => {
    const loggedUser = this.props.loggedUser;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      if (userType !== 'invited' && userType !== 'teamOwner') return;
      const checklistItems = getChecklistItems(userType);
      const stepId =
        userType === 'invited' ? 'add_comment_invited' : 'add_comment';

      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        this.props.setMessages,
        this.props.updateClientData,
      );
    }
  };

  initFyoutCard = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        card: this.props.boardCard,
      },
    });
  };

  handleFlyoutClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleKeyDown = (e: KeyboardEvent) => {
    const target = e.target as HTMLTextAreaElement;

    if (e.key === 'Escape') {
      e.preventDefault();

      // Reset fields to initial values where possible if they have been edited.
      if (Object.keys(this.state.initialCard).includes(target.name)) {
        const key = target.name as keyof InitialCard;
        const contentChanged =
          this.props.boardCard[key] !== this.state.initialCard[key];

        if (contentChanged) {
          const content = { [key]: this.state.initialCard[key] };
          this.props.updateCard(this.props.boardCard.id, content);
          return;
        }
      }

      const isContextMenuOpened = Boolean(
        document.querySelector('.context-menu-component'),
      );
      const isImageViewerOpened = Boolean(
        document.querySelector('.image-viewer-component'),
      );
      const isDialogOpened = Boolean(
        document.querySelector('.dialog-component'),
      );
      // prevent closing the flyout if a context menu is opened
      // let the context menu close first
      if (isContextMenuOpened || isImageViewerOpened || isDialogOpened) return;

      this.handleFlyoutClose();
    }
  };

  handleCloseButtonKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleFlyoutClose();
    }
  };

  handleArchiveCard = async () => {
    try {
      await archiveCard(this.props.boardCard.id);
      this.props.removeCardsFromColumn(this.props.columnId!, [
        this.props.boardCard.id,
      ]);
      this.handleFlyoutClose();
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      this.setState({
        serverErrors: error,
      });
    }
  };

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      },
    });
  }

  onTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.updateCard(this.props.boardCard.id, {
      title: event.target.value,
    });
  };

  saveTitle = async () => {
    if (this.props.boardCard.title !== this.state.initialCard.title) {
      this.saveCard({ title: this.props.boardCard.title });
    }
  };

  onDescriptionChange = (value: string, cardId?: string) => {
    // cardId is used for the case when we want to save the description of the previous card (switch cards while editing)
    this.saveCard({ description: value }, cardId);
    this.tutorialAction_updateDescription();
  };

  saveDescription = async () => {
    if (
      this.props.boardCard.description !== this.state.initialCard.description
    ) {
      this.saveCard({ description: this.props.boardCard.description });
    }
  };

  saveCard = async (payload: Partial<InitialCard>, cardId?: string) => {
    try {
      const updatedCard = await updateCard(
        cardId ? cardId : this.props.boardCard.id,
        payload,
      );

      const updatedValues: Partial<InitialCard> = {};
      updatedValues.title = updatedCard.title;
      updatedValues.description = updatedCard.description;
      updatedValues.numberOfAttachments =
        this.props.boardCard.numberOfAttachments;
      updatedValues.numberOfComments = this.props.boardCard.numberOfComments;

      // Update initial values to match the values on the server.
      //
      // In case the user opens a card, saves a new title, changes the
      // new title, pressing escape will revert to the last saved title.
      this.setState((prevState) => {
        return {
          initialCard: {
            ...prevState.initialCard,
            ...updatedValues,
          },
        };
      });

      // Update card values with those on the server in case the server
      // trims or manipulates the values in any way.
      this.props.updateCard(
        cardId ? cardId : this.props.boardCard.id,
        updatedValues,
      );
    } catch (error) {
      showErrorNotifications(error, this.props.setMessages);
    }
  };

  handleAddAttachment = async () => {
    this.props.addAttachment(this.props.boardCard.id);
    this.tutorialAction_uploadAttachment();
  };

  handleRemoveAttachment = async () => {
    this.props.removeAttachment(this.props.boardCard.id);
  };

  handleAddComment = async () => {
    this.props.addComment(this.props.boardCard.id);
    this.tutorialAction_addComment();
  };

  handleDeleteComment = async () => {
    this.props.deleteComment(this.props.boardCard.id);
  };

  handleAddSubtask = async () => {
    this.props.addSubtask(this.props.boardCard.id);
  };

  handleUpdateSubtask = async (checked: boolean) => {
    this.props.updateSubtask(this.props.boardCard.id, checked);
  };

  handleRemoveSubtask = async (checked: boolean) => {
    this.props.removeSubtask(this.props.boardCard.id, checked);
  };

  render() {
    const { t } = this.props;
    return (
      <CardFlyout
        disabled={false}
        cardNrPrefix={this.props.cardNrPrefix!}
        boardCard={this.props.boardCard}
        tags={this.props.tags}
        priorities={this.props.priorities}
        members={this.props.members}
        serverErrors={this.state.serverErrors}
        descriptionEditor={this.state.descriptionEditor}
        handleFlyoutClose={this.props.onClose}
        handleCloseButtonKeyDown={this.handleCloseButtonKeyDown}
        setMessages={this.props.setMessages}
        setDescriptionEditor={this.setDescriptionEditor}
        onTitleChange={this.onTitleChange}
        saveTitle={this.saveTitle}
        onDescriptionChange={this.onDescriptionChange}
        columnTitles={this.props.columnTitles}
        boardUser={this.props.boardUser}
        addWsListener={this.props.addWsListener}
        removeWsListener={this.props.removeWsListener}
        columnId={this.props.columnId}
        handleAddAttachment={this.handleAddAttachment}
        handleRemoveAttachment={this.handleRemoveAttachment}
        handleAddComment={this.handleAddComment}
        handleDeleteComment={this.handleDeleteComment}
        clipboardData={this.props.clipboardData}
        setUploadClipboardData={this.props.setUploadClipboardData}
        uploadClipboardData={this.props.uploadClipboardData}
        selectedCardData={this.props.selectedCardData}
        handleAddSubtask={this.handleAddSubtask}
        handleUpdateSubtask={this.handleUpdateSubtask}
        handleRemoveSubtask={this.handleRemoveSubtask}
      >
        <>
          <li>
            <ContextMenu
              dept={0}
              title={t('cardOptions')}
              triggerContent={
                <>
                  <span className="fas fa-ellipsis-h"></span>
                </>
              }
              triggerClassDefault="ghost-button"
              triggerClassActive="secondary-button"
            >
              <li>
                <Button
                  className="ghost-button"
                  onClick={this.handleArchiveCard}
                  disabled={
                    !['admin', 'owner'].includes(this.props.boardUser.role)
                  }
                >
                  <span className="fal fa-archive icon"></span>
                  <span className="text">{t('archiveCard')}</span>
                </Button>
              </li>
            </ContextMenu>
          </li>
        </>
      </CardFlyout>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      loggedUser: ctx.loggedUser,
      setMessages: ctx.notifications.setMessages!,
      updateClientData: ctx.updateClientData!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      tags: ctx.board.tags,
      priorities: ctx.board.priorities,
      members: ctx.board.members,
      updateCard: ctx.updateCard,
      removeCardsFromColumn: ctx.removeCardsFromColumn,
      boardUser: ctx.board.user,
      addWsListener: ctx.addWsListener,
      removeWsListener: ctx.removeWsListener,
      addAttachment: ctx.addAttachment,
      removeAttachment: ctx.removeAttachment,
      addComment: ctx.addComment,
      deleteComment: ctx.deleteComment,
      addSubtask: ctx.addSubtask,
      updateSubtask: ctx.updateSubtask,
      removeSubtask: ctx.removeSubtask,
    };
  },
};
export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('boardCardFlyout')(CardDetail),
  AppContextAdapter,
  BoardContextAdapter,
);
