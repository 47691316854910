import React from 'react';
import { BoardItemDTO } from '../../../../common/api/dtos/Board';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import BoardListingCard from '../../../partials/board/BoardListing/BoardListingCard';
import NoBoardsMessage from './NoBoardsMessage';
import ArchivedBoardsPage from '../../Board/BoardArchive/ArchivedBoardsPage';
import ErrorList from '../../../error/ErrorList/ErrorList';
import { useTranslation } from 'react-i18next';
import SubscriptionStatus from './SubscriptionStatus';
import UtilityTeamBar from './UtilityTeamBar';

interface TeamBoardsSectionProps {
  team: TeamDTO;
  boards: BoardItemDTO[];
  showArchive: boolean;
  serverErrors: string[];
  toggleArchiveView: (teamId: string) => void;
  hideTeam: (teamId: string) => void;
}

const TeamBoardsSection: React.FC<TeamBoardsSectionProps> = ({
  team,
  boards,
  showArchive,
  serverErrors,
  toggleArchiveView,
  hideTeam,
}) => {
  const { t } = useTranslation('home');

  return (
    <div className="flex-row fill">
      <div className="column">
        <div className="flex-row no-reflow fill flex-v-center flex-h-spread">
          <div className="column py-2xs">
            <h2 className="primary-title h3 normalcase multiline-ellipsis l1">
              <img
                alt={t('teamIcon')}
                className="icon"
                src={`${process.env.REACT_APP_ICONS_PATH}/boards.svg`}
              />{' '}
              <span>{team.name}</span>
              <ErrorList errors={serverErrors} />
            </h2>
          </div>
          <UtilityTeamBar
            boards={boards}
            team={team}
            showArchive={showArchive}
            toggleArchiveView={toggleArchiveView}
            hideTeam={hideTeam}
          />
        </div>
        <SubscriptionStatus team={team} />
        {showArchive ? (
          <ArchivedBoardsPage
            teamId={team.id}
            toggleArchiveView={() => toggleArchiveView(team.id)}
          />
        ) : boards && boards.length > 0 ? (
          <div className="flex-row card-list-component pt-0 fade-in-grow">
            {boards.map((board: BoardItemDTO) => (
              <div
                key={board.id}
                className="column px-2xs pt-xs pb-0 flex-v-stretch"
              >
                <BoardListingCard
                  thumbnail={board.thumbnail}
                  id={board.id}
                  name={board.name}
                  color={board.color}
                  favorite={board.favorite}
                  archived={board.archived}
                  description={board.description}
                  disabled={team.subscription.deleteAt !== null}
                />
              </div>
            ))}
          </div>
        ) : (
          <NoBoardsMessage
            disabled={
              (team.role !== 'admin' && team.role !== 'owner') ||
              team.subscription.deleteAt !== null
            }
            team={team}
          />
        )}
      </div>
    </div>
  );
};

export default TeamBoardsSection;
