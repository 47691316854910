import React, { ChangeEvent, Component } from 'react';
import { BoardDTO } from '../../../../common/api/dtos/Board';
import { listBoards } from '../../../../common/api/endpoints/board';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import BoardCardArchived from '../../../partials/board/BoardListing/BoardCardArchived';
import ErrorList from '../../../error/ErrorList/ErrorList';
import TextBox from '../../../controls/TextBox/TextBox';
import { getFilteredItems } from '../../../../common/helpers/getFilteredItems';
import ContentLoader from '../../../partials/ContentLoader/ContentLoader';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

export interface Props extends WithTranslation {
  teamId?: string | null;
  toggleArchiveView?: () => void;
}

interface State {
  filterValue: string;
  status: TRequestStatus;
  restoreStatus: TRequestStatus;
  deleteStatus: TRequestStatus;
  boards: BoardDTO[];
  serverErrors: string[];
}

class ArchivedBoardsPage extends Component<Props, State> {
  availableFilters: string[] = ['name', 'description'];

  constructor(props: Props) {
    super(props);

    this.state = {
      filterValue: '',
      status: 'idle',
      restoreStatus: 'idle',
      deleteStatus: 'idle',
      boards: [],
      serverErrors: [],
    };
  }

  componentDidMount(): void {
    this.fetchArchivedBoards();
  }

  fetchArchivedBoards = async () => {
    this.setState({ status: 'loading' });

    try {
      const archivedBoards = await listBoards({
        name: 'boards',
        params: [
          { param: 'teamId', value: this.props.teamId || null },
          { param: 'archived', value: true },
          { param: 'owned', value: true },
        ],
      });

      this.setState({ status: 'success', boards: archivedBoards.boards });
    } catch (err) {
      const error = Array.isArray(err) ? err : [err as string];

      this.setState({ status: 'error', serverErrors: error });
    }
  };

  removeFromArchive = (id: string) => {
    this.setState((prevState) => ({
      boards: prevState.boards.filter((board) => board.id !== id),
    }));
  };

  setFilterValue = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ filterValue: ev.target.value });
  };

  renderFilter() {
    const { t } = this.props;
    const { filterValue, boards } = this.state;
    return (
      <div className="flex-row fill pr-0">
        <div className="column pb-0">
          <TextBox
            label={t('filterLabel')}
            type="text"
            name="filterBox"
            id="filterBox"
            onChange={this.setFilterValue}
            placeholder={t('filterPlaceholder')}
            srOnly={true}
            tight={true}
            autoComplete="off"
            ariaAutocomplete="none"
          />
        </div>
      </div>
    );
  }

  renderContent() {
    const { t } = this.props;
    const { status, boards, filterValue, serverErrors } = this.state;
    const filteredArchivedBoards = getFilteredItems(
      filterValue,
      this.availableFilters,
      boards,
    );

    if (status === 'loading') {
      return (
        <ContentLoader
          width="50%"
          height="111px"
        />
      );
    }

    if (filteredArchivedBoards.length > 0) {
      return filteredArchivedBoards.map((board) => (
        <div
          className="flex-row fill pr-0"
          key={board.id}
        >
          <div className="column medium pb-2xs pt-0 pr-0">
            <BoardCardArchived
              id={board.id}
              name={board.name}
              description={board.description}
              removeFromArchive={this.removeFromArchive}
              owned={board.owned}
              thumbnail={board.thumbnail}
              color={board.color}
            />
          </div>
        </div>
      ));
    }

    if (status === 'success') {
      if (filterValue.trim() !== '') {
        return (
          <div className="flex-row">
            <div className="column flex-v-stretch">
              <p className="faint-text">{t('noArchivedBoardsMatchFilter')}</p>
            </div>
          </div>
        );
      }
      if (boards.length === 0) {
        return (
          <div className="flex-row fill">
            <div className="column">
              <p className="faint-text">{t('noArchivedBoards')}</p>
            </div>
          </div>
        );
      }
    }

    return null;
  }

  render() {
    const { t } = this.props;
    const { serverErrors } = this.state;

    return (
      <div className="flex-row fill fade-in-grow">
        <div className="column px-2xs pt-xs pb-0">
          <div className="fade-in-grow card opaque">
            <button
              className="ghost-button top-tight right-tight"
              onClick={() =>
                this.props.toggleArchiveView
                  ? this.props.toggleArchiveView()
                  : null
              }
            >
              <span className="fal fa-times text-md"></span>
            </button>
            <h3 className="text-sm text-500 mb-xs">
              <span>{t('boardArchiveTitle')} </span>
            </h3>
            <hr className="separator mt-sm mb-xs" />
            <ErrorList errors={serverErrors} />
            {this.renderFilter()}
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('boardArchive')(ArchivedBoardsPage);
