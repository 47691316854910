import React from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { BoardItemDTO } from '../../../../common/api/dtos/Board';
import BoardListingCard from '../../../partials/board/BoardListing/BoardListingCard';
import ContentLoader from '../../../partials/ContentLoader/ContentLoader';
import ErrorList from '../../../error/ErrorList/ErrorList';
import CreateBoardButton from './CreateBoardButton';
import ArchivedBoardsPage from '../../Board/BoardArchive/ArchivedBoardsPage';
import { useTranslation } from 'react-i18next';
import ContextMenu from '../../../controls/ContextMenu/ContextMenu';
import LinkButton from '../../../controls/LinkButton/LinkButton';
import AppContext, {
  IAppContext,
} from '../../../../common/contexts/AppContext';
import { TFunction } from 'i18next';
import dayjsHelper from '../../../../common/helpers/dayjsHelper';
import NoBoardsMessage from './NoBoardsMessage';
import { NavLink } from 'react-router-dom';

interface PersonalBoardsSectionProps {
  boards: BoardItemDTO[];
  status: TRequestStatus;
  showArchive: boolean;
  serverErrors: string[];
  toggleArchiveView: () => void;
}

const renderSubscriptionStatus = (
  subscription: string,
  t: TFunction<'home'>,
  context: IAppContext,
  usedFreeBoard: boolean,
): JSX.Element | null => {
  if (subscription === 'unsubscribed') {
    return <></>;
  } else if (subscription === 'willCancel') {
    return (
      <div className="flex-row fill">
        <div className="column px-2xs pt-xs pb-0">
          <div className="card px-xs py-xs">
            <div className="content py-xs">
              <p>
                <span className="fas px-xs accent-text-yellow fa-exclamation-circle "></span>
                {t('personalWillCancel', {
                  cancelAt: dayjsHelper(
                    context.loggedUser?.subscription?.cancelAt,
                  ).format('MMMM Do, YYYY'),
                })}{' '}
                {t('avoidGracePrefix')}{' '}
                <NavLink
                  to={`/account/plans`}
                  className={'link-button'}
                >
                  <span className="text">{t('avoidGraceMid')}</span>
                </NavLink>{' '}
                {t('avoidGraceSuffix')}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (subscription === 'willDelete') {
    return (
      <>
        <div className="flex-row fill">
          <div className="column px-2xs pt-xs pb-0">
            <div className="card px-xs py-xs">
              <div className="content py-xs">
                <p>
                  <span className="fas px-xs accent-text-red fa-exclamation-circle"></span>
                  {t('personalWillDelete', {
                    deleteAt: dayjsHelper(
                      context.loggedUser?.subscription?.deleteAt,
                    ).format('MMMM Do, YYYY'),
                  })}
                  <NavLink
                    to={`/account/plans`}
                    className={'link-button'}
                  >
                    <span className="text"> {t('reactivate')}</span>
                  </NavLink>{' '}
                  {t('reactivateRest')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column px-2xs pt-xs pb-0">
            <div className="card px-xs py-xs">
              <div className="content py-xs">
                <p>
                  <span className="fas px-xs accent-text-blue fa-info-circle "></span>
                  {!usedFreeBoard
                    ? t('noFreeBoardCreated')
                    : t('withFreeBoardCreated')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

const PersonalBoardsSection: React.FC<PersonalBoardsSectionProps> = ({
  boards,
  status,
  showArchive,
  serverErrors,
  toggleArchiveView,
}) => {
  const { t } = useTranslation('home');
  const context = React.useContext(AppContext);

  let subscription:
    | 'subscribed'
    | 'unsubscribed'
    | 'willCancel'
    | 'willDelete'
    | 'none' = 'none';

  if (context.loggedUser!.subscription) {
    if (context.loggedUser!.subscription.deleteAt) {
      subscription = 'willDelete';
    } else if (context.loggedUser!.subscription.cancelAt) {
      subscription = 'willCancel';
    } else {
      subscription = 'subscribed';
    }
  } else {
    subscription = 'unsubscribed';
  }

  // Determine if the user has used their free board
  const usedFreeBoard = boards.some(
    (board: BoardItemDTO) => board.paid === false,
  );

  return (
    <div className="flex-row fill">
      <div className="column pt-0">
        <div className="flex-row no-reflow fill flex-v-center flex-h-spread">
          <div className="column py-2xs">
            <h2 className="primary-title h3 normalcase no-wrap">
              <img
                alt="boards icon"
                className="icon"
                src={`${process.env.REACT_APP_ICONS_PATH}/boards.svg`}
              />{' '}
              <span>{t('personal')}</span>
              <ErrorList errors={serverErrors} />
            </h2>
          </div>
          <div className="column py-2xs pr-2xs">
            <ul className="control-list-component flex-h-end no-wrap">
              <li>
                {usedFreeBoard ? (
                  subscription === 'willDelete' ? (
                    <LinkButton
                      to="/account/plans"
                      className="ghost-button"
                    >
                      <span className="fas fa-rocket-launch icon"></span>
                      <span className="text">{t('reactivatePlan')}</span>
                    </LinkButton>
                  ) : (
                    <LinkButton
                      to="/account/plans"
                      className="ghost-button"
                    >
                      <span className="fas fa-rocket-launch icon"></span>
                      <span className="text">{t('upgradePlan')}</span>
                    </LinkButton>
                  )
                ) : (
                  <CreateBoardButton
                    disabled={false}
                    shrinkOnMobile
                  />
                )}
              </li>
              <li>
                <ContextMenu
                  dept={0}
                  title={t('personalOptions')}
                  triggerContent={
                    <span className="icon fas fa-ellipsis-h"></span>
                  }
                  triggerClassDefault="ghost-button"
                  triggerClassActive="secondary-button"
                >
                  <li className={showArchive ? 'active' : ''}>
                    <button
                      className="ghost-button"
                      onClick={toggleArchiveView}
                    >
                      <span className="fal fa-archive icon"></span>
                      <span className="text">{t('archivedBoards')}</span>
                    </button>
                  </li>
                  <li>
                    <hr />
                  </li>
                  <li>
                    {subscription === 'unsubscribed' ? (
                      <LinkButton
                        to="/account/plans"
                        className="ghost-button"
                      >
                        <span className="fas fa-rocket-launch icon accent-text"></span>
                        <span className="text">{t('upgradePlan')}</span>
                      </LinkButton>
                    ) : subscription === 'subscribed' ? (
                      <LinkButton
                        to="/account/plans"
                        className="ghost-button"
                      >
                        <span className="fas fa-rocket-launch icon"></span>
                        <span className="text">{t('managePlan')}</span>
                      </LinkButton>
                    ) : subscription === 'willCancel' ||
                      subscription === 'willDelete' ? (
                      <LinkButton
                        to="/account/plans"
                        className="ghost-button"
                      >
                        <span className="fas fa-rocket-launch icon"></span>
                        <span className="text">{t('reactivatePlan')}</span>
                      </LinkButton>
                    ) : null}
                  </li>
                </ContextMenu>
              </li>
            </ul>
          </div>
        </div>

        {renderSubscriptionStatus(subscription, t, context, usedFreeBoard)}
        {status === 'loading' ? (
          <div className="flex-row card-list-component">
            <ContentLoader height="98px" />
          </div>
        ) : showArchive ? (
          <ArchivedBoardsPage toggleArchiveView={toggleArchiveView} />
        ) : (
          <div className="flex-row card-list-component pt-0 fade-in-grow">
            {boards.length !== 0 ? (
              boards.map((board: BoardItemDTO) => (
                <div
                  key={board.id}
                  className="column px-2xs pt-xs pb-0 flex-v-stretch"
                >
                  <BoardListingCard
                    thumbnail={board.thumbnail}
                    id={board.id}
                    name={board.name}
                    color={board.color}
                    favorite={board.favorite}
                    archived={board.archived}
                    description={board.description}
                    willDelete={
                      subscription === 'willDelete' && board.paid === true
                    }
                    disabled={
                      subscription === 'willDelete' && board.paid === true
                    }
                  />
                </div>
              ))
            ) : (
              <NoBoardsMessage />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalBoardsSection;
