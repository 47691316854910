import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateBoardButton from './CreateBoardButton';
import { TeamDTO } from '../../../../common/api/dtos/Team';

interface NoBoardsMessageProps {
  disabled?: boolean;
  team?: TeamDTO;
}

const NoBoardsMessage: React.FC<NoBoardsMessageProps> = ({
  disabled,
  team,
}) => {
  const { t } = useTranslation('home');
  return (
    <div className="flex-row pt-0 fill fade-in-grow">
      <div className="column px-2xs pt-xs pb-0">
        <div className="card px-xs py-3xs">
          <ul className="control-list-component py-xs flex-h-spread">
            <li>
              <p>
                <span className="fas px-xs accent-text-blue fa-info-circle "></span>
                {team
                  ? team?.role === 'owner'
                    ? t('noBoardsTeamOwners')
                    : t('noBoardsTeam')
                  : t('noBoards')}
              </p>
            </li>
            {!disabled && (
              <li>
                <CreateBoardButton
                  disabled={false}
                  team={team}
                />
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NoBoardsMessage;
