import React, { Component, createRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AppContext, {
  IAppContext,
} from '../../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../../common/contexts/NotificationsContext';
import BoardTopbar from '../../../partials/Topbar/BoardTopbar';
import BoardCardFlyout, {
  BoardCard,
} from '../../../partials/board/BoardCardFlyout/CardDetail';
import Flyout from '../../../partials/Flyout/Flyout';
import { ColumnDTO } from '../../../../common/api/dtos/Column';
import BoardColumnPlaceholder from '../../../partials/board/BoardColumn/BoardColumnPlaceholder';
import {
  DragDropContext,
  DragUpdate,
  Droppable,
  DropResult,
} from '../../../../vendors/wavemyth/react-beautiful-dnd/src';
import { moveColumn } from '../../../../common/api/endpoints/column';
import { moveCard } from '../../../../common/api/endpoints/card';
import HorizontalDrag from '../../../partials/HorizontalDrag/HorizontalDrag';
import { UserDTO } from '../../../../common/api/dtos/User';
import { createSlug } from '../../../../common/helpers/createSlug';
import {
  setBoardFavicon,
  setLocalStorageBoardColor,
} from '../../../../common/helpers/boardColor';
import { CARD_DATA_LABELS } from '../../../../common/configs/CardDataLabels';
import { TCardData } from '../../../../common/types/CardData';
import { withContextAdapters } from '../../../partials/ContextAdapter/withContextAdapter';
import BoardContext from '../../../../common/contexts/BoardContext';
import BoardColumnCollapsed from '../../../partials/board/BoardColumn/BoardColumnCollapsed';
import BoardColumnExpanded from '../../../partials/board/BoardColumn/BoardColumnExpanded/BoardColumnExpanded';
import {
  addCardToUrl,
  removeCardFromUrl,
} from '../../../../common/helpers/cardUrl';
import scrollToCard from '../../../../common/helpers/scrollToCard';
import { getScrollBehavior } from '../../../../common/helpers/getScrollBehavior';
import { unite } from '../../../../common/helpers/unite';
import {
  MsgArchiveAllCards,
  MsgArchiveCard,
  MsgDeleteColumn,
  MsgMoveCard,
  MsgWs,
} from '../BoardProvider/wsHandlers';
import isSupportedImageMimeType from '../../../../common/helpers/isSupportedImageMimeType';
import { Provided } from '../../../../vendors/wavemyth/react-beautiful-dnd/src/view/draggable/draggable-types';
import {
  showErrorNotifications,
  showInfoNotifications,
} from '../../../../common/helpers/showNotifications';
import { throttle } from 'lodash';
import { t } from 'i18next';
import Tutorial from '../../../partials/Onboarding/Tutorial/Tutorial';
import { getChecklistItems } from '../../../partials/Onboarding/Tutorial/tutorialData';
import getProgressFromClientData from '../../../../common/helpers/getProgressFromClientData';
import { patchClientData } from '../../../../common/api/endpoints/user';
import { updateTutorialStep } from '../../../../common/helpers/tutorialHelper';
import selectTutorial from '../../../../common/helpers/selectTutorial';
import WelcomeDialog from '../../../partials/Onboarding/WelcomeDialog';
import { IClientData } from '../../../../common/interfaces/ClientData';
import { List } from 'react-virtualized';
import {
  IBoard,
  IBoardContext,
  ICard,
  UpdateBoardSettingsData,
} from '../../../../common/interfaces/BoardContext';

interface RouteParams {
  routeBoardId: string;
  boardSlug?: string;
  cardId?: string;
}
interface AppContextProps {
  updateClientData: (clientData: Partial<IClientData>) => void;
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
  setIsDragging: (value: boolean) => void;
}
interface BoardContextProps {
  board: IBoard;
  reloadBoard: () => void;
  toggleFavorite: () => void;
  addColumn: (column: ColumnDTO) => void;
  deleteColumn: (id: string) => void;
  updateColumn: (id: string, data: { title: string }) => void;
  removeCardsFromColumn: (columnId: string, cardIds: string[]) => void;
  moveColumn: (columnId: string, index: number) => void;
  addCard: (columnId: string, card: ICard) => void;
  moveCard: (
    cardId: string,
    srcColId: string,
    srcColIndex: number,
    dstColId: string,
    dstColIndex: number,
  ) => number;
  setBoardSettings: (
    boardSettings: UpdateBoardSettingsData,
    callback?: () => void,
  ) => void;
  setCurrentBoardUser: (member: { id: string; role: string }) => void;
  addWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  removeWsListener: (code: string, callback: (message: MsgWs) => void) => void;
}
interface ExternalProps extends RouteComponentProps<RouteParams> {
  loggedUser: UserDTO;
}
interface Props extends ExternalProps, AppContextProps, BoardContextProps {}

export interface State {
  loadingMemberIds: string[];
  loadingTagIds: string[];
  selectedCardData: TCardData[];
  collapsedColumns: Record<string, boolean>;
  flyoutAnimation: boolean;
  flyoutCard?: { id: string; columnId: string } | null;
  flyoutShouldUpdate: boolean;
  title: string;
  metadata: string;
  clipboardData: File | null;
  uploadClipboardData: boolean;
  overscanRowCount: number;
  isClosing: boolean;
  needToScrollToCard: boolean;
}

class BoardDetail extends Component<Props, State> {
  cardRefs: Record<string, React.RefObject<HTMLDivElement>>;
  private flyoutAnimationTimer: NodeJS.Timeout | null = null;
  private dragDropContextRef: React.RefObject<typeof DragDropContext>;
  private virtualListRefs: Record<string, React.RefObject<List>> = {}; // Store refs for each column
  private columnRefs: Record<string, React.RefObject<HTMLDivElement>> = {}; // Store refs for each column
  private boardDetailRef = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.cardRefs = {};
    this.dragDropContextRef = React.createRef(); // Initialize the ref

    setBoardFavicon(this.props.match.params.routeBoardId);

    const cardData = localStorage.getItem(
      `${this.props.loggedUser.id}-${props.board.id}-cardData`,
    );
    const defaultSelectedCardData = cardData
      ? JSON.parse(cardData)
      : Object.keys(CARD_DATA_LABELS);
    const cleanedName = props.board.name.replaceAll(/%/g, '');

    const boardSlug = createSlug(cleanedName);
    if (
      this.props.match.params.boardSlug !== boardSlug &&
      !this.props.match.params.cardId
    ) {
      this.updateBoardUrlWithSlug(props.board.id, boardSlug);
    }
    setLocalStorageBoardColor(this.props.board.id, this.props.board.color);

    const storageKey = `${this.props.loggedUser.id}-${this.props.board.id}-collapsedColumns`;
    const collapsedColumns = localStorage.getItem(storageKey);

    this.state = {
      loadingMemberIds: [],
      loadingTagIds: [],
      selectedCardData: defaultSelectedCardData,
      collapsedColumns: collapsedColumns ? JSON.parse(collapsedColumns) : {},
      flyoutAnimation: this.props.match.params.cardId ? false : true,
      flyoutShouldUpdate: false,
      title: 'borddo - create tickets, track work, finish tasks',
      metadata: 'borddo by wavemyth',
      clipboardData: null,
      uploadClipboardData: false,
      overscanRowCount: 5,
      isClosing: false,
      needToScrollToCard: false,
    };
  }

  setVirtualListRef = (columnId: string) => {
    // Create or return the existing ref for the column
    if (!this.virtualListRefs[columnId]) {
      this.virtualListRefs[columnId] = createRef<List>();
    }
    return this.virtualListRefs[columnId];
  };
  setColumnRef = (columnId: string) => {
    // Create or return the existing ref for the column
    if (!this.columnRefs[columnId]) {
      this.columnRefs[columnId] = createRef<HTMLDivElement>();
    }
    return this.columnRefs[columnId];
  };
  setCardRef = (cardId: string, cardRef: React.RefObject<HTMLDivElement>) => {
    this.cardRefs[cardId] = cardRef;
  };

  componentDidMount() {
    document.documentElement.classList.add('hide-y');
    this.setState(
      {
        title: `${this.props.board.name} - borddo`,
        metadata: this.props.board.description.slice(0, 150),
      },
      () => {
        this.updateTitleAndMeta();
      },
    );

    if (this.props.match.params.cardId) {
      this.selectCardFromUrl(this.props.match.params.cardId);
    }

    if (this.props.match.params.cardId) {
      this.flyoutAnimationTimer = setTimeout(() => {
        this.setState({
          flyoutAnimation: true,
        });
      }, 500);
    }

    this.props.addWsListener('archive_card', this.handleWsCloseFlyout);
    this.props.addWsListener('archive_all_cards', this.handleWsCloseFlyout);
    this.props.addWsListener('delete_column', this.handleWsCloseFlyout);
    this.props.addWsListener('move_card', this.handleWsMoveCard);
    window.addEventListener('resize', this.checkFlyoutOpened);
  }

  componentDidUpdate(prevProps: Props) {
    let oldCardId = prevProps.match.params.cardId;
    let newCardId = this.props.match.params.cardId;

    if (oldCardId !== newCardId) {
      this.selectCardFromUrl(newCardId);
    }
    this.checkFlyoutOpened();

    if (this.state.needToScrollToCard) {
      const { flyoutCard } = this.state;
      if (flyoutCard) {
        const cardRef = this.cardRefs[flyoutCard.id];
        if (cardRef && cardRef.current) {
          const virtualListRef = this.virtualListRefs[flyoutCard.columnId];
          const cardIndex = this.getCardIndex(
            flyoutCard.columnId,
            flyoutCard.id,
          );
          scrollToCard(
            cardRef.current,
            getScrollBehavior(),
            virtualListRef,
            cardIndex,
            flyoutCard.id,
          );
          this.setState({ needToScrollToCard: false });
        }
      }
    }
  }

  getCardIndex = (columnId: string, cardId: string) => {
    const column = this.props.board.columns.find((col) => col.id === columnId);
    const cardIndex = column?.cards.findIndex((card) => card.id === cardId);
    return cardIndex !== undefined ? cardIndex : -1;
  };

  componentWillUnmount(): void {
    document.documentElement.classList.remove('hide-y');
    this.props.removeWsListener('archive_card', this.handleWsCloseFlyout);
    this.props.removeWsListener('archive_all_cards', this.handleWsCloseFlyout);
    this.props.removeWsListener('delete_column', this.handleWsCloseFlyout);
    this.props.removeWsListener('move_card', this.handleWsMoveCard);
    window.removeEventListener('resize', this.checkFlyoutOpened);

    if (this.flyoutAnimationTimer) {
      clearTimeout(this.flyoutAnimationTimer);
    }
  }

  checkFlyoutOpened = () => {
    const HTMLElement = document.querySelector('.CONDUIT');
    const mobileWidth = 767;
    if (this.state.flyoutCard && window.innerWidth <= mobileWidth) {
      HTMLElement?.classList.add('v-scroll');
    } else if (this.state.flyoutCard && window.innerWidth > mobileWidth) {
      HTMLElement?.classList.remove('v-scroll');
    }
  };

  handleWsCloseFlyout = (message: MsgWs) => {
    const wsMessage = message as
      | MsgArchiveCard
      | MsgArchiveAllCards
      | MsgDeleteColumn
      | MsgMoveCard;
    const useColumnId =
      wsMessage.type === 'archive_all_cards' ||
      wsMessage.type === 'delete_column';

    if (
      this.state.flyoutCard &&
      wsMessage &&
      wsMessage.data.id ===
        (useColumnId
          ? this.state.flyoutCard.columnId
          : this.state.flyoutCard.id)
    ) {
      this.setState({
        flyoutCard: null,
      });
    }
  };

  handleWsMoveCard = (message: MsgWs) => {
    const moveCardMessage = message as MsgMoveCard;
    const { id, newColumnId, oldColumnId } = moveCardMessage.data;

    if (this.state.flyoutCard?.id === id) {
      // Find the card in the old column
      const card = this.findCard(oldColumnId, id);

      if (card) {
        // Find the card's new index in the destination column
        const newColumn = this.props.board.columns.find(
          (col) => col.id === newColumnId,
        );
        const cardIndex = newColumn?.cards.findIndex((card) => card.id === id);

        if (cardIndex !== undefined && cardIndex !== -1) {
          // Call setFlyoutCard with the new column and index
          this.setFlyoutCard(newColumnId, card, cardIndex);
        } else {
          this.setState({ flyoutCard: null });
        }
      } else {
        this.setState({ flyoutCard: null });
      }
    }
  };

  updateTitleAndMeta = () => {
    document.title = this.state.title;
    let metaTag = document.querySelector("meta[name='description']");
    if (metaTag !== null) {
      if (this.state.metadata.length === 0) {
        metaTag.setAttribute('content', 'borddo by wavemyth');
      } else metaTag.setAttribute('content', this.state.metadata);
    }
  };

  selectCardFromUrl = (cardId?: string) => {
    if (!cardId) {
      this.clearFlyoutCard();
      this.setState(
        {
          title: `${this.props.board.name} - borddo`,
          metadata: this.props.board.description.slice(0, 150),
        },
        () => {
          this.updateTitleAndMeta();
        },
      );
    } else {
      let found = false;

      // Loop through columns to find the card
      for (const col of this.props.board.columns) {
        const card = col.cards.find((card) => card.id === cardId);
        const cardIndex = col.cards.findIndex((card) => card.id === cardId);

        if (card && cardIndex !== undefined && cardIndex !== -1) {
          this.setFlyoutCard(col.id, card, cardIndex); // Pass the columnId, card, and cardIndex
          found = true;

          this.setState(
            {
              title: `${card.title} - borddo`,
              metadata: card.description.slice(0, 150),
            },
            () => {
              this.updateTitleAndMeta();
            },
          );
          break;
        }
      }

      if (!found) {
        this.props.history.replace(
          `/board/${this.props.board.id}/board-archived-cards/view/card/${cardId}`,
        );
      }
    }
  };

  updateBoardUrlWithSlug(id: string, slug: string) {
    const { pathname } = this.props.history.location;
    const boardid = pathname.indexOf(id);

    if (boardid === -1) {
      return;
    }

    const urlBase = pathname.substring(0, boardid + id.length);
    const newURL = `${urlBase}/view/${slug}`;

    this.props.history.replace(newURL);
  }

  setNewColumn = (column: ColumnDTO) => {
    this.props.addColumn(column);
  };

  setUpdatedColumn = (id: string, title: string) => {
    this.props.updateColumn(id, { title });
  };

  setUpdateBoardSettings = (boardSettings: UpdateBoardSettingsData) => {
    this.props.setBoardSettings(boardSettings);
  };

  setDeletedColumn = (id: string) => {
    if (id === this.state.flyoutCard?.columnId) {
      this.setState({
        flyoutCard: null,
      });
    }

    this.props.deleteColumn(id);
  };

  setAllArchivedCards = (id: string, archivedIds: string[]) => {
    if (id === this.state.flyoutCard?.columnId) {
      this.setState({
        flyoutCard: null,
      });
    }

    this.props.removeCardsFromColumn(id, archivedIds);
  };

  setNewCard = (columnId: string, card: ICard) => {
    this.props.addCard(columnId, card);

    if (this.state.flyoutCard) {
      this.setState(
        {
          flyoutShouldUpdate: true,
        },
        () => {
          this.selectCard(columnId, card.id);
        },
      );
    }
  };

  toggleFavorite = () => {
    this.props.toggleFavorite();
  };

  onDragEnd = async (result: DropResult) => {
    this.props.setIsDragging(false);
    const { destination, source, draggableId, type } = result;
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      setTimeout(() => {
        if (this.context.cardRef) {
          this.context.cardRef?.current?.classList.add('flashing-border');
        }
      }, 10);
      return;
    }

    if (type === 'column') {
      this.props.moveColumn(draggableId, destination.index);
      try {
        await moveColumn(draggableId, destination.index);
      } catch (err) {
        console.debug(err);
        this.props.reloadBoard();
      }
    } else {
      const columnChanged = source.droppableId !== destination.droppableId;

      const destIndex = this.props.moveCard(
        draggableId,
        source.droppableId,
        source.index,
        destination.droppableId,
        destination.index,
      );

      this.setState((prevState) => {
        let flyoutCard = prevState.flyoutCard;
        if (flyoutCard && flyoutCard.id === draggableId) {
          flyoutCard = {
            ...flyoutCard,
            columnId: destination.droppableId,
          };
        }
        return { flyoutCard };
      });

      try {
        if (this.props.board.columnCardLimitState === 'enabled') {
          const column = this.props.board.columns.find(
            (column) => column.id === destination.droppableId,
          );

          const initialCardCount = column?.visibleCards.length;

          await moveCard(draggableId, destination.droppableId, destIndex);

          const updatedColumn = this.props.board.columns.find(
            (column) => column.id === destination.droppableId,
          );

          const newCardCount = updatedColumn?.visibleCards.length;

          if (
            updatedColumn?.cardLimit &&
            updatedColumn.cardLimit > 0 &&
            (newCardCount ?? 0) > (initialCardCount ?? 0) &&
            (newCardCount ?? 0) > updatedColumn.cardLimit
          ) {
            showInfoNotifications(
              'msg.info.cardLimitReached',
              this.props.setMessages,
            );
          }
        }

        await moveCard(draggableId, destination.droppableId, destIndex);

        if (columnChanged) {
          this.tutorialAction_moveCardBetweenColumns();
        }
      } catch (err) {
        showErrorNotifications(err, this.props.setMessages);
        this.props.reloadBoard();
      }
    }
  };

  tutorialAction_moveCardBetweenColumns = async () => {
    const loggedUser = this.props.loggedUser;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      const checklistItems = getChecklistItems(userType);
      const stepId =
        userType === 'solo' || userType === 'invited'
          ? 'move_cards_between_columns'
          : 'move_cards_between_columns_team';

      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        this.props.setMessages,
        this.props.updateClientData,
      );
    }
  };

  setSelectedCardData = (cardData?: TCardData) => {
    if (cardData) {
      this.setState(
        (prevState) => {
          const tempCardData = [...prevState.selectedCardData];

          if (tempCardData?.includes(cardData)) {
            const removeCardDataIndex = tempCardData.findIndex(
              (selectedCardData) => selectedCardData === cardData,
            );
            tempCardData.splice(removeCardDataIndex, 1);
          } else {
            tempCardData.push(cardData);
          }

          return {
            selectedCardData: tempCardData,
          };
        },
        () => {
          localStorage.setItem(
            `${this.props.loggedUser.id}-${this.props.board.id}-cardData`,
            JSON.stringify(this.state.selectedCardData),
          );
        },
      );
    } else {
      this.setState(
        {
          selectedCardData: Object.keys(CARD_DATA_LABELS) as TCardData[],
        },
        () => {
          localStorage.setItem(
            `${this.props.loggedUser.id}-${this.props.board.id}-cardData`,
            JSON.stringify(this.state.selectedCardData),
          );
        },
      );
    }
  };

  handleExpandColumn = (columnId: string) => {
    this.setState((prevState) => {
      const collapsedColumns = { ...prevState.collapsedColumns };
      delete collapsedColumns[columnId];

      const storageKey = `${this.props.loggedUser.id}-${this.props.board.id}-collapsedColumns`;
      localStorage.setItem(storageKey, JSON.stringify(collapsedColumns));

      return { collapsedColumns };
    });
  };

  handleCollapseColumn = (columnId: string) => {
    this.setState((prevState) => {
      const collapsedColumns = { ...prevState.collapsedColumns };
      collapsedColumns[columnId] = true;

      const storageKey = `${this.props.loggedUser.id}-${this.props.board.id}-collapsedColumns`;
      localStorage.setItem(storageKey, JSON.stringify(collapsedColumns));

      return { collapsedColumns };
    });
  };

  selectCard = (columnId: string | null, cardId: string) => {
    if (!columnId) return;

    // Find the column and card
    const column = this.props.board.columns.find((col) => col.id === columnId);
    const card = column?.cards.find((card) => card.id === cardId);
    const cardIndex = column?.cards.findIndex((card) => card.id === cardId);

    if (card && cardIndex !== undefined && cardIndex !== -1) {
      addCardToUrl(this.props, card, this.props.board.cardNrPrefix);

      this.tutorialAction_openCard();
      this.setFlyoutCard(columnId, card, cardIndex);

      if (this.state.flyoutShouldUpdate) {
        this.setState({
          flyoutShouldUpdate: false,
        });
      }
    }
  };

  tutorialAction_openCard = async () => {
    const loggedUser = this.props.loggedUser;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      if (userType !== 'invited') return;
      const checklistItems = getChecklistItems(userType);
      const stepId = 'open_card';

      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        this.props.setMessages,
        this.props.updateClientData,
      );
    }
  };

  setFlyoutCard = (columnId: string, card: ICard, cardIndex: number) => {
    const isColumnCollapsed = this.state.collapsedColumns[columnId];
    if (isColumnCollapsed) {
      // Expand the column
      this.handleExpandColumn(columnId);

      // Set the flyoutCard and needToScrollToCard flag
      this.setState(
        {
          flyoutCard: {
            id: card.id,
            columnId: columnId,
          },
          needToScrollToCard: true,
        },
        () => {
          // Scroll will happen in componentDidUpdate
        },
      );
    } else {
      // Column is already expanded; proceed to set flyoutCard and scroll immediately
      this.setState(
        {
          flyoutCard: {
            id: card.id,
            columnId: columnId,
          },
        },
        () => {
          const virtualListRef = this.virtualListRefs[columnId];
          const cardRef = this.cardRefs[card.id];

          scrollToCard(
            cardRef?.current || null,
            getScrollBehavior(),
            virtualListRef,
            cardIndex,
            card.id,
          );
        },
      );
    }
  };

  clearFlyoutCard = () => {
    this.setState((prevState) => {
      return {
        flyoutCard: prevState.flyoutCard === undefined ? undefined : null,
      };
    });
  };

  onFlyoutClose = () => {
    this.setState(
      {
        isClosing: true,
      },
      () => {
        setTimeout(() => {
          let cardRef = this.cardRefs[this.state.flyoutCard?.id!];
          if (typeof cardRef !== 'undefined') {
            cardRef.current?.parentElement?.focus();
          }
          removeCardFromUrl(this.props, this.props.board.name);
          this.setState({ flyoutCard: null, isClosing: false });
        }, 200);
      },
    );
  };

  findCard = (columnId: string, cardId: string) => {
    // Find the column where the card currently exists
    let column = this.props.board.columns.find((c) =>
      c.cards.some((card) => card.id === cardId),
    );

    if (!column) {
      throw new Error(`Expected to find card with id ${cardId} in any column`);
    }

    const card = column.cards.find((card) => card.id === cardId);

    if (!card) {
      throw new Error(`Card with id ${cardId} could not be found in column`);
    }

    return card;
  };

  setUploadClipboardData = (value: boolean) => {
    this.setState({
      uploadClipboardData: value,
    });
  };

  onDragStart = () => {
    this.props.setIsDragging(true);
    if (window.navigator.vibrate) {
      window.navigator.vibrate(10);
    }
  };

  handleDragUpdate = throttle((update: DragUpdate) => {}, 100);

  render() {
    let flyoutCard = null;
    const hasAccess =
      this.props.board.user?.role === 'owner' ||
      this.props.board.user?.role === 'admin';
    let showFlyout =
      this.state.flyoutCard === undefined
        ? undefined
        : Boolean(this.state.flyoutCard);

    if (this.state.flyoutCard && this.state.isClosing !== true) {
      flyoutCard = this.findCard(
        this.state.flyoutCard.columnId,
        this.state.flyoutCard.id,
      );
    }

    return (
      <>
        <div
          ref={this.boardDetailRef}
          className={unite('card-board-component flyout-sibling pl-0', {
            'flyout-open': Boolean(
              flyoutCard && localStorage.getItem('flyoutState') == null,
            ),
          })}
        >
          <BoardTopbar
            boardName={this.props.board.name}
            boardSlug={createSlug(this.props.board.name)}
            favorite={this.props.board.favorite}
            toggleFavorite={this.toggleFavorite}
            enableFilter={true}
            routeBoardId={this.props.match.params.routeBoardId}
            selectedCardData={this.state.selectedCardData}
            setSelectedCardData={this.setSelectedCardData}
            history={this.props.history}
            location={this.props.location}
            match={this.props.match}
          />
          <WelcomeDialog />
          <DragDropContext
            onDragEnd={this.onDragEnd}
            // sensors={[CustomTouchSensor]}
            onDragStart={this.onDragStart}
            onDragUpdate={this.handleDragUpdate}
          >
            <Droppable
              droppableId="all-columns"
              direction="horizontal"
              type="column"
            >
              {(provided: Provided) => (
                <div
                  className="flex-row flyout-sibling-inner fe-scroll-container no-reflow"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <HorizontalDrag>
                    {this.props.board.columns.map((boardColumn, index) => {
                      let selectedCardId;
                      if (this.state.flyoutCard?.columnId === boardColumn.id) {
                        selectedCardId = this.state.flyoutCard.id;
                      }

                      if (this.state.collapsedColumns[boardColumn.id]) {
                        return (
                          <BoardColumnCollapsed
                            key={boardColumn.id}
                            index={index}
                            boardColumn={boardColumn}
                            handleExpandColumn={this.handleExpandColumn}
                            boardFilterValue={this.props.board.cardFilter.value}
                            columnCardLimitState={
                              this.props.board.columnCardLimitState
                            }
                          />
                        );
                      } else {
                        return (
                          <BoardColumnExpanded
                            key={boardColumn.id}
                            ref={this.setColumnRef(boardColumn.id)}
                            index={index}
                            boardColumn={boardColumn}
                            cardNrPrefix={this.props.board.cardNrPrefix!}
                            selectedCardId={selectedCardId}
                            selectedCardData={this.state.selectedCardData}
                            tags={this.props.board.tags}
                            priorities={this.props.board.priorities}
                            members={this.props.board.members}
                            setCardRef={this.setCardRef}
                            selectCard={this.selectCard}
                            setUpdatedColumn={this.setUpdatedColumn}
                            setDeletedColumn={this.setDeletedColumn}
                            setUpdateBoardSettings={this.setUpdateBoardSettings}
                            setAllArchivedCards={this.setAllArchivedCards}
                            setNewCard={this.setNewCard}
                            handleCollapseColumn={this.handleCollapseColumn}
                            setMessages={this.props.setMessages}
                            boardFilterValue={this.props.board.cardFilter.value}
                            overscanRowCount={this.state.overscanRowCount}
                            columnCardLimitState={
                              this.props.board.columnCardLimitState
                            }
                            listRef={this.setVirtualListRef(boardColumn.id)} // Pass the ref here
                          />
                        );
                      }
                    })}
                    {provided.placeholder}
                    <BoardColumnPlaceholder
                      setNewColumn={this.setNewColumn}
                      routeBoardId={this.props.match.params.routeBoardId}
                      hasAccess={hasAccess}
                    />
                    <div className="flyout-padder"></div>
                  </HorizontalDrag>
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Flyout
            showFlyout={showFlyout}
            flyoutAnimation={this.state.flyoutAnimation}
            isClosing={this.state.isClosing}
          >
            {flyoutCard && this.state.flyoutCard && (
              <BoardCardFlyout
                boardCard={flyoutCard as BoardCard}
                columnId={this.state.flyoutCard.columnId}
                handleExpandColumn={this.handleExpandColumn}
                cardNrPrefix={this.props.board.cardNrPrefix!}
                onClose={this.onFlyoutClose}
                columnTitles={this.props.board.columns.map((c) => {
                  return {
                    id: c.id,
                    title: c.title,
                  };
                })}
                history={this.props.history}
                location={this.props.location}
                clipboardData={this.state.clipboardData!}
                setUploadClipboardData={this.setUploadClipboardData}
                uploadClipboardData={this.state.uploadClipboardData}
                selectedCardData={this.state.selectedCardData}
              />
            )}
          </Flyout>
        </div>
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      updateClientData: ctx.updateClientData!,
      setMessages: ctx.notifications.setMessages!,
      setIsDragging: ctx.setIsDragging!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      board: ctx.board,
      setBoardSettings: ctx.updateBoardSettings!,
      setCurrentBoardUser: ctx.setCurrentBoardUser!,
      reloadBoard: ctx.reloadBoard,
      toggleFavorite: ctx.toggleFavorite,
      addColumn: ctx.addColumn,
      deleteColumn: ctx.removeColumn,
      updateColumn: ctx.updateColumn,
      removeCardsFromColumn: ctx.removeCardsFromColumn,
      moveColumn: ctx.moveColumn,
      addCard: ctx.addCard,
      moveCard: ctx.moveCard,
      addWsListener: ctx.addWsListener,
      removeWsListener: ctx.removeWsListener,
    };
  },
};
export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(BoardDetail, AppContextAdapter, BoardContextAdapter);
