export default function isSupportedVideoMimeType(mimeType: string): boolean {
  const supportedVideoMimeTypes: Set<string> = new Set([
    'video/mp4',
    'video/webm',
    'video/ogg',
    'video/quicktime',
    'video/x-msvideo', // AVI
    'video/x-ms-wmv', // WMV
    'video/mpeg',
  ]);

  return supportedVideoMimeTypes.has(mimeType);
}
