import React, { FormEvent } from 'react';
import { TContextAlignment } from '../../../common/types/ContextAlignment';
import { TContextMenu } from '../../../common/types/ContextMenu';
import ContextMenu from '../../controls/ContextMenu/ContextMenu';
import ColumnMenu from './ColumnMenu';
import ColumnArchive from './ColumnArchive';
import ColumnDelete from './ColumnDelete';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import ColumnLimit from './ColumnLimit';
import { BoardColumnExpandedProps } from '../../partials/board/BoardColumn/BoardColumnExpanded/partials/TypeUtils';

interface Props extends WithTranslation {
  setSelectedTags?: (tags: string[]) => void;
  contextMenuClassName: TContextAlignment;
  contextId?: string;
  triggerClassDefault: string;
  triggerClassActive: string;
  iconClass?: string;
  setDeletedColumn: () => void;
  onArchiveAllCards: () => void;
  handleCollapseColumn: () => void;
  onSetLimit: (e: FormEvent) => void;
  setLimit: (value: number) => void;
  totalCards: number;
  limit?: number;
  columnCardLimitState: string;
}

interface State {
  selectedContext: TContextMenu;
}

class ColumnContext extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedContext: 'manageColumn',
    };
  }

  setSelectedContext = (context: TContextMenu) => {
    this.setState({
      selectedContext: context,
    });
  };

  evaluateDept = (): number => {
    switch (this.state.selectedContext) {
      case 'manageColumn':
        return 0;
      case 'archiveColumn':
      case 'deleteColumn':
      case 'setLimit':
        return 1;
      default:
        return 0;
    }
  };

  render() {
    const { t } = this.props;
    const contextComponents: { [key: string]: React.ReactElement } = {
      manageColumn: (
        <ColumnMenu
          setSelectedContext={this.setSelectedContext}
          handleCollapseColumn={this.props.handleCollapseColumn}
        />
      ),
      archiveColumn: (
        <ColumnArchive
          directionalButton={{
            text: t('backToColumnOptions'),
            direction: 'left',
            onClick: () => this.setSelectedContext('manageColumn'),
          }}
          setSelectedContext={this.setSelectedContext}
          onArchiveAllCards={this.props.onArchiveAllCards}
          totalCards={this.props.totalCards}
        />
      ),
      deleteColumn: (
        <ColumnDelete
          directionalButton={{
            text: t('backToColumnOptions'),
            direction: 'left',
            onClick: () => this.setSelectedContext('manageColumn'),
          }}
          setSelectedContext={this.setSelectedContext}
          setDeletedColumn={this.props.setDeletedColumn}
          totalCards={this.props.totalCards}
        />
      ),
      setLimit: (
        <ColumnLimit
          directionalButton={{
            text: t('backToColumnOptions'),
            direction: 'left',
            onClick: () => this.setSelectedContext('manageColumn'),
          }}
          setSelectedContext={this.setSelectedContext}
          onSetLimit={this.props.onSetLimit}
          setLimit={this.props.setLimit}
          limit={this.props.limit}
          totalCards={this.props.totalCards}
          columnCardLimitState={this.props.columnCardLimitState}
        />
      ),
    };

    return (
      <ContextMenu
        dept={this.evaluateDept()}
        resetSelectedContext={() => this.setSelectedContext('manageColumn')}
        contextMenuClassName={this.props.contextMenuClassName}
        triggerClassActive={this.props.triggerClassActive}
        triggerClassDefault={this.props.triggerClassDefault}
        title={t('columnOptions')}
        triggerContent={
          <>
            <span
              className={`${
                this.props.iconClass
                  ? this.props.iconClass
                  : 'fas fa-ellipsis-h icon'
              }`}
            ></span>
          </>
        }
      >
        {contextComponents[this.state.selectedContext]}
      </ContextMenu>
    );
  }
}

export default withStyledTranslation('columnContext')(ColumnContext);
