import { archiveAllCards } from '../../../../../../common/api/endpoints/column';
import {
  showErrorNotifications,
  showSuccessNotifications,
} from '../../../../../../common/helpers/showNotifications';
import { BoardColumnExpandedProps } from './TypeUtils';

export async function handleArchiveAllCards(props: BoardColumnExpandedProps) {
  const { t } = props;
  try {
    const result = await archiveAllCards(props.boardColumn.id);
    const archivedIds = result.cards.map((card) => card.id);
    props.setAllArchivedCards(props.boardColumn.id, archivedIds);

    const notifications =
      result.cards.length === 1
        ? [
            t('archivedCardsSingular', {
              columnTitle: props.boardColumn.title,
            }),
          ]
        : [
            t('archivedCards', {
              cardCount: result.cards.length,
              columnTitle: props.boardColumn.title,
            }),
          ];

    showSuccessNotifications(notifications, props.setMessages);
  } catch (err) {
    showErrorNotifications(err, props.setMessages);
  }
}
