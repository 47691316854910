import React from 'react';
import { getAccess } from '../../../../common/api/endpoints/board';
import { RouteComponentProps } from 'react-router-dom';
import PageMessage from '../../../partials/PageMessage/PageMessage';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import AppContext from '../../../../common/contexts/AppContext';

import BoardContext, {
  addCard,
  addColumn,
  addMember,
  addPriority,
  addTag,
  assignMember,
  assignTag,
  filterCards,
  moveCard,
  moveColumn,
  movePriority,
  removeCardsFromColumn,
  removeColumn,
  removeMember,
  removePriority,
  removeTag,
  restoreCard,
  updateBoardSettings,
  unassignMember,
  unassignTag,
  updateCard,
  updateColumn,
  updateMember,
  updatePriority,
  updateTag,
  CardFilterField,
  changeBoardOwner,
  updateInvitee,
  deleteArchivedCard,
  addAttachment,
  removeAttachment,
  addComment,
  deleteComment,
  clearFilterCards,
  addSubtask,
  updateSubtask,
  removeSubtask,
} from '../../../../common/contexts/BoardContext';
import { ColumnDTO } from '../../../../common/api/dtos/Column';
import { PriorityDTO } from '../../../../common/api/dtos/Priority';
import { TagDTO } from '../../../../common/api/dtos/Tag';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import LinkButton from '../../../controls/LinkButton/LinkButton';
import {
  MsgAddBoardPriority,
  MsgAddBoardTag,
  MsgAddCard,
  MsgAddColumn,
  MsgArchiveAllCards,
  MsgArchiveCard,
  MsgAssignTag,
  MsgAssignUser,
  MsgDeleteBoardPriority,
  MsgDeleteBoardTag,
  MsgDeleteCard,
  MsgDeleteColumn,
  MsgInviteUser,
  MsgMoveBoardPriority,
  MsgMoveCard,
  MsgMoveColumn,
  MsgOpenBoard,
  MsgRemoveUser,
  MsgRestoreCard,
  MsgUnassignTag,
  MsgUnassignUser,
  MsgUpdateBoardPriority,
  MsgUpdateBoardSettings,
  MsgUpdateBoardTag,
  MsgUpdateBoardUser,
  MsgUpdateCard,
  MsgUpdateColumn,
  MsgAddAttachment,
  MsgRemoveAttachment,
  MsgCreateComment,
  MsgDeleteComment,
  MsgWs,
  MsgCreateSubtask,
  MsgUpdateSubtask,
  MsgRemoveSubtask,
} from './wsHandlers';
import { ArchivedCard } from '../CardArchive/ArchivedCardsPage';
import { ConnectionNotification } from './ConnectionNotification';
import {
  IBoardContext,
  IBoard,
  UpdateColumnData,
  ICard,
  UpdatePriorityData,
  UpdateTagData,
  UpdateMemberData,
  UpdateBoardSettingsData,
  UpdateCardData,
} from '../../../../common/interfaces/BoardContext';

interface RouteParams {
  routeBoardId: string;
}

export interface Props extends RouteComponentProps<RouteParams> {
  computeConduit: (styles: any) => any;
  HTMLTag: HTMLElement | null;
}

interface State {
  boardContext?: IBoardContext;
  isArchived: boolean;
  archivedBoardsLink: string;
  isOwned: boolean;
  userRemovedFromBoard: boolean;
  notFound: boolean;
  isLoading: boolean;
  connectionStatus: 'lost' | 'reconnecting' | 'online' | null;
  firstConnection: boolean;
  isPersonal: boolean;
}

type CustomWebSocket = WebSocket & { reconnect: () => void };

class BoardProvider extends React.Component<Props, State> {
  context!: React.ContextType<typeof AppContext>;
  private ws?: CustomWebSocket;
  private wsConnectionPromise: Promise<void>;

  private wsTimeoutHandler?: number;
  // these should be configurable
  private wsConnectionDelay = 1_000;
  private wsTimeoutInterval = 10_000;

  private connectionStatusHandler?: number;
  private connectionStatusTimeout = 5_000;

  private listeners: any[];

  constructor(props: Props) {
    super(props);
    this.state = {
      isArchived: false,
      isOwned: false,
      userRemovedFromBoard: false,
      notFound: false,
      isLoading: true,
      archivedBoardsLink: '/',
      connectionStatus: null,
      firstConnection: true,
      isPersonal: true,
    };
    this.wsConnectionPromise = this.connectWs();

    this.listeners = [];

    this.loadBoard();
  }

  componentDidMount(): void {
    if (this.state.boardContext && this.props.HTMLTag) {
      this.props.HTMLTag.className = this.props.computeConduit(
        this.state.boardContext?.board,
      );
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.state.boardContext && this.props.HTMLTag) {
      this.props.HTMLTag.className = this.props.computeConduit(
        this.state.boardContext?.board,
      );
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      const { boardContext } = this.state;
      clearFilterCards(boardContext!.board);
      if (boardContext) {
        const isArchivePage = this.props.location.pathname.includes(
          'board-archived-cards',
        );
        const localStorageValue = localStorage.getItem(
          `boardFilterValue-${boardContext.board.id}-${boardContext.board.user.id}`,
        ) as string;
        const localStorageFields = localStorage.getItem(
          `boardFilterFields-${boardContext.board.id}-${boardContext.board.user.id}`,
        ) as string;

        this.setState(
          {
            boardContext: {
              ...boardContext,
              board: {
                ...boardContext.board,
                cardFilter: {
                  value: isArchivePage ? null : localStorageValue,
                  fields: isArchivePage
                    ? ['title']
                    : localStorageFields
                    ? JSON.parse(localStorageFields)
                    : ['title'],
                  count: 0,
                },
              },
            },
          },
          () => {
            if (this.state.boardContext?.board.cardFilter.value) {
              this.filterCards(
                this.state.boardContext.board.cardFilter.value,
                this.state.boardContext.board.cardFilter.fields,
              );
            }
          },
        );
      }
    }
  }

  componentWillUnmount(): void {
    if (this.props.HTMLTag) {
      this.props.HTMLTag.className = this.props.computeConduit(undefined);
    }
    if (this.ws) {
      this.closeWs(this.ws);
    }
  }

  connectWs = async () => {
    // Close current connection before opening a new one
    if (this.ws) {
      await this.closeWs(this.ws);
    }

    // initialize websocket connection
    try {
      this.ws = (await this.initWs(
        this.props.match.params.routeBoardId,
      )) as CustomWebSocket;
    } catch (err) {
      setTimeout(() => {
        this.updateConnectionStatus('reconnecting');
        this.wsConnectionPromise = this.wsConnectionPromise.finally(
          this.connectWs,
        );
      }, this.wsConnectionDelay);
      return;
    }

    this.ws.reconnect = () => {
      this.updateConnectionStatus('reconnecting');
      // Wait `wsConnectionDelay` then try to connect the websocket again.
      setTimeout(() => {
        this.wsConnectionPromise = this.wsConnectionPromise.finally(
          this.connectWs,
        );
      }, this.wsConnectionDelay);
    };

    // We are logging all events for debugging purposes.
    this.ws.onopen = (...args) => console.debug('ws open', ...args);
    this.ws.onerror = (...args) => console.debug('ws error', ...args);
    this.ws.onclose = (...args) => console.debug('ws close', ...args);

    // try to reconnect in case of an error if the connection is lost.
    this.ws.addEventListener('error', this.ws.reconnect);
    this.ws.addEventListener('close', this.ws.reconnect);

    this.ws.onmessage = (event) => {
      let message: MsgWs;

      try {
        message = JSON.parse(event.data);
      } catch (err) {
        // log error in case message is not valid json and do nothing
        console.error(err);
        return;
      }

      console.log('message', message);

      const msgType = message.type;

      if (this.listeners && this.listeners.length > 0) {
        this.listeners.forEach((listener) => {
          if (message.type === listener.code) {
            listener.callback(message);
          }
        });
      }

      switch (message.type) {
        //
        // Connection messages
        //
        // board
        case 'heartbeat':
          return this.handleHeartbeat();
        case 'open_board':
          return this.handleOpenBoard(message);
        case 'update_board_settings':
          return this.handleBoardSettingsUpdate(message);
        case 'archive_board':
          return this.handleArchiveBoard();

        // member
        case 'update_user_role':
          return this.handleUpdateBoardUser(message);
        case 'invite_user':
          return this.handleInviteUser(message);
        case 'remove_user':
          return this.handleRemoveUser(message);

        // column
        case 'add_column':
          return this.handleAddColumn(message);
        case 'update_column':
          return this.handleUpdateColumn(message);
        case 'move_column':
          return this.handleMoveColumn(message);
        case 'delete_column':
          return this.handleDeleteColumn(message);
        case 'archive_all_cards':
          return this.handleArchiveAllCards(message);

        // card
        case 'add_card':
          return this.handleAddCard(message);
        case 'restore_card':
          return this.handleRestoreCard(message);
        case 'archive_card':
          return this.handleArchiveCard(message);
        case 'move_card':
          return this.handleMoveCard(message);
        case 'delete_card':
          return this.handleDeleteCard(message);
        case 'update_card':
          return this.handleUpdateCard(message);
        case 'assigne_user':
          return this.handleAssignUser(message);
        case 'unassigne_user':
          return this.handleUnassignUser(message);
        case 'assigne_tag':
          return this.handleAssignTag(message);
        case 'unassigne_tag':
          return this.handleUnassignTag(message);

        // attachments
        case 'add_attachment':
          return this.handleAddAttachment(message);
        case 'remove_attachment':
          return this.handleRemoveAttachment(message);

        // comments
        case 'add_comment':
          return this.handleAddComment(message);
        case 'delete_comment':
          return this.handleDeleteComment(message);

        // priority
        case 'add_board_priority':
          return this.handleAddBoardPriority(message);
        case 'update_board_priority':
          return this.handleUpdateBoardPriority(message);
        case 'delete_board_priority':
          return this.handleDeleteBoardPriority(message);
        case 'move_board_priority':
          return this.handleMoveBoardPriority(message);

        // tag
        case 'add_tag':
          return this.handleAddBoardTag(message);
        case 'update_tag':
          return this.handleUpdateBoardTag(message);
        case 'delete_tag':
          return this.handleDeleteBoardTag(message);

        case 'add_subtask':
          return this.handleAddSubtask(message);
        case 'update_subtask':
          return this.handleUpdateSubtask(message);
        case 'remove_subtask':
          return this.handleRemoveSubtask(message);

        default:
          console.warn(`Unhandled message type '${msgType}'`);
      }
    };
  };

  initWs = (boardId: string): Promise<WebSocket> => {
    return new Promise((resolve, reject) => {
      let ws: WebSocket;
      try {
        let url = `${
          process.env.REACT_APP_WS_BASE_URL
        }/board/${encodeURIComponent(boardId)}/connect`;
        ws = new WebSocket(url);
      } catch (err) {
        reject(err);
        return;
      }

      const onSuccess = () => {
        this.updateConnectionStatus('online');
        ws.removeEventListener('open', onSuccess);
        ws.removeEventListener('error', onFailure);
        ws.removeEventListener('close', onFailure);
        resolve(ws);
      };
      const onFailure = (err: Event) => {
        this.updateConnectionStatus('lost');
        ws.removeEventListener('open', onSuccess);
        ws.removeEventListener('error', onFailure);
        ws.removeEventListener('close', onFailure);
        reject(err);
      };

      ws.addEventListener('open', onSuccess);
      ws.addEventListener('error', onFailure);
      ws.addEventListener('close', onFailure);
    });
  };

  updateConnectionStatus = (status: 'lost' | 'reconnecting' | 'online') => {
    // don't show connection status if first connection
    if (this.state.firstConnection) {
      this.setState({ firstConnection: false });
      return;
    }

    this.setState(
      {
        connectionStatus: status,
      },
      () => {
        if (this.connectionStatusHandler) {
          clearTimeout(this.connectionStatusHandler);
        }
        const timeoutId = setTimeout(() => {
          this.setState({ connectionStatus: null });
        }, this.connectionStatusTimeout);

        // return of setTimeout in browser is a positive integer, as such, this type cast is valid
        this.connectionStatusHandler = timeoutId as unknown as number;
      },
    );
  };

  closeWs = (ws: CustomWebSocket): Promise<void> => {
    if (ws.readyState === WebSocket.CLOSED) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      // remove reconnection listeners to avoid recreacting the connection
      ws.removeEventListener('close', ws.reconnect);
      ws.removeEventListener('error', ws.reconnect);

      ws.addEventListener('close', () => {
        resolve();
      });
      ws.addEventListener('error', (err) => {
        reject(err);
      });

      ws.close();
    });
  };

  addWsListener = (code: string, callback: (data: MsgWs) => void) => {
    this.listeners.push({ code, callback });
  };

  removeWsListener = (code: string, callback: (data: MsgWs) => void) => {
    this.listeners = this.listeners.filter(
      (e) => e.code !== code && e.callback !== callback,
    );
  };

  handleHeartbeat() {
    clearTimeout(this.wsTimeoutHandler);
    this.ws!.send(JSON.stringify({ type: 'heartbeat' }));

    // If more than `wsTimeoutInterval` milliseconds have past since last heartbeat, we consider
    // we have lost the connection.
    const timeoutId = setTimeout(() => {
      console.warn('Connection lost, closing');
      this.ws?.close();
    }, this.wsTimeoutInterval);

    // return of setTimeout in browser is a positive integer, as such, this type cast is valid
    this.wsTimeoutHandler = timeoutId as unknown as number;
  }

  handleOpenBoard(message: MsgOpenBoard) {
    // When the connection is established we get the board and the connection id. All following
    // message are applied over this initial state of the board. In the unlikely case of a race
    // condition, this board might be different than the one returned by `getBoard`.
    const { connectionId, board } = message.data;
    const localStorageValue = localStorage.getItem(
      `boardFilterValue-${board.id}-${board.idOnBoard}`,
    ) as string;
    const localStorageFields = localStorage.getItem(
      `boardFilterFields-${board.id}-${board.idOnBoard}`,
    ) as string;

    board.invitees.forEach((e) => {
      e.invited = true;
    });

    this.setState(
      {
        boardContext: {
          board: {
            ...board,
            columns: board.columns.map((column: ColumnDTO) => {
              return {
                ...column,
                cards: column.cards as ICard[],
                visibleCards: column.cards as ICard[],
              };
            }),
            archivedCards: null,
            members: [board.owner, ...board.invitees, ...board.members],
            user: {
              id: board.idOnBoard,
              role: board.role,
            },
            cardFilter: {
              value: window.location.href.includes('board-archived-cards')
                ? null
                : localStorageValue,
              fields: window.location.href.includes('board-archived-cards')
                ? ['title']
                : !window.location.href.includes('board-archived-cards') &&
                  localStorageFields
                ? JSON.parse(localStorageFields)
                : ['title'],
              count: 0,
            },
          },

          loadingMemberIds: [],
          loadingTagIds: [],
          activeBoardMembersStatus: [],
          setLoadingMemberIds: this.setLoadingMemberIds,
          setLoadingTagIds: this.setLoadingTagIds,
          setActiveBoardMembersStatus: this.setActiveBoardMembersStatus,

          reloadBoard: this.reloadBoard,
          toggleFavorite: this.toggleFavorite,
          addColumn: this.addColumn,
          removeColumn: this.deleteColumn,
          updateColumn: this.updateColumn,
          removeCardsFromColumn: this.removeCardsFromColumn,
          moveColumn: this.moveColumn,

          addCard: this.addCard,
          restoreCard: this.restoreCard,
          updateCard: this.updateCard,
          moveCard: this.moveCard,
          assignMember: this.assignMember,
          unassignMember: this.unassignMember,
          assignTag: this.assignTag,
          unassignTag: this.unassignTag,
          addAttachment: this.addAttachment,
          removeAttachment: this.removeAttachment,
          filterCards: this.filterCards,
          addComment: this.addComment,
          deleteComment: this.deleteComment,
          addSubtask: this.addSubtask,
          updateSubtask: this.updateSubtask,
          removeSubtask: this.removeSubtask,

          addPriority: this.addPriority,
          updatePriority: this.updatePriority,
          movePriority: this.movePriority,
          removePriority: this.removePriority,

          addTag: this.addTag,
          updateTag: this.updateTag,
          removeTag: this.removeTag,

          addMember: this.addMember,
          updateMember: this.updateMember,
          removeMember: this.removeMember,

          updateBoardSettings: this.updateBoardSettings,
          setArchived: this.setArchived,
          setArchivedCards: this.setArchivedCards,

          addWsListener: this.addWsListener,
          removeWsListener: this.removeWsListener,
        },
        isLoading: false,
      },
      () => {
        if (this.state.boardContext?.board.cardFilter.value) {
          this.filterCards(
            this.state.boardContext?.board.cardFilter.value,
            this.state.boardContext?.board.cardFilter.fields,
          );
        }
      },
    );

    // By passing the connection id with requests we initiate, we are telling the server that
    // this connection should not receive messages generated by those requests.
    //
    // Session storage is used to provide the ws connection id in the api functions to avoid
    // refactoring. This assumes session storage is per browser tab/page and that only one
    // ws connection is used per browser tab/page.
    sessionStorage.setItem('borddo-wsconid', connectionId);
  }

  handleBoardSettingsUpdate(message: MsgUpdateBoardSettings) {
    this.updateBoardSettings(message.data);
  }
  handleArchiveBoard() {
    this.setArchived();
  }

  // member
  handleUpdateBoardUser(message: MsgUpdateBoardUser) {
    this.updateMember(message.data.id, message.data);
  }
  handleInviteUser(message: MsgInviteUser) {
    this.addMember(message.data);
  }
  handleRemoveUser(message: MsgRemoveUser) {
    this.removeMember(message.data.id);
    if (this.state.boardContext?.board.user.id === message.data.id) {
      this.setState({
        userRemovedFromBoard: true,
        boardContext: undefined,
      });
    }
  }

  // column
  handleAddColumn(message: MsgAddColumn) {
    this.addColumn({ ...message.data, cards: [] });
  }
  handleUpdateColumn(message: MsgUpdateColumn) {
    this.updateColumn(message.data.id, { title: message.data.title });
  }
  handleMoveColumn(message: MsgMoveColumn) {
    this.moveColumn(message.data.id, message.data.index);
  }
  handleDeleteColumn(message: MsgDeleteColumn) {
    this.deleteColumn(message.data.id);
  }
  handleArchiveAllCards(message: MsgArchiveAllCards) {
    const boardColumns = this.state.boardContext?.board.columns.map((c) => c);
    const column = boardColumns?.find((c) => c.id === message.data.id);
    const cardIds = column?.cards.map((c) => c.id);

    if (cardIds && cardIds.length > 0) {
      this.removeCardsFromColumn(message.data.id, cardIds);
    }
  }

  // card
  handleAddCard(message: MsgAddCard) {
    this.addCard(message.data.columnId!, message.data);
  }

  handleRestoreCard(message: MsgRestoreCard) {
    this.restoreCard(message.data.columnId, message.data);
  }

  handleMoveCard(message: MsgMoveCard) {
    const { id, index, newColumnId, oldColumnId } = message.data;
    const oldColumn = this.state.boardContext?.board.columns.find(
      (c) => c.id === oldColumnId,
    );
    const oldCardIndex = oldColumn?.cards.findIndex((c) => c.id === id);

    this.moveCard(id, oldColumnId, oldCardIndex!, newColumnId, index);
  }
  handleArchiveCard(message: MsgArchiveCard) {
    const { id, columnId } = message.data;
    this.removeCardsFromColumn(columnId, [id]);
  }
  handleDeleteCard(message: MsgDeleteCard) {
    this.deleteArchivedCard(message.data.id);
  }
  handleUpdateCard(message: MsgUpdateCard) {
    this.updateCard(message.data.id, message.data);
  }
  handleAssignUser(message: MsgAssignUser) {
    this.assignMember(message.data.id, message.data.userId);
  }
  handleUnassignUser(message: MsgUnassignUser) {
    this.unassignMember(message.data.id, message.data.userId);
  }
  handleAssignTag(message: MsgAssignTag) {
    this.assignTag(message.data.id, message.data.tagId);
  }
  handleUnassignTag(message: MsgUnassignTag) {
    this.unassignTag(message.data.id, message.data.tagId);
  }

  // attachments
  handleAddAttachment(message: MsgAddAttachment) {
    this.addAttachment(message.data.cardId);
  }
  handleRemoveAttachment(message: MsgRemoveAttachment) {
    this.removeAttachment(message.data.cardId);
  }

  // comments
  handleAddComment(message: MsgCreateComment) {
    this.addComment(message.data.cardId);
  }
  handleDeleteComment(message: MsgDeleteComment) {
    this.deleteComment(message.data.cardId);
  }

  // priority
  handleAddBoardPriority(message: MsgAddBoardPriority) {
    this.addPriority(message.data);
  }
  handleUpdateBoardPriority(message: MsgUpdateBoardPriority) {
    this.updatePriority(message.data.id, message.data);
  }
  handleDeleteBoardPriority(message: MsgDeleteBoardPriority) {
    this.removePriority(message.data.id);
  }
  handleMoveBoardPriority(message: MsgMoveBoardPriority) {
    this.movePriority(message.data.id, message.data.index);
  }

  // tag
  handleAddBoardTag(message: MsgAddBoardTag) {
    this.addTag(message.data);
  }
  handleUpdateBoardTag(message: MsgUpdateBoardTag) {
    this.updateTag(message.data.id, message.data);
  }
  handleDeleteBoardTag(message: MsgDeleteBoardTag) {
    this.removeTag(message.data.id);
  }

  // tag
  handleAddSubtask(message: MsgCreateSubtask) {
    this.addSubtask(message.data.cardId);
  }

  handleUpdateSubtask(message: MsgUpdateSubtask) {
    this.updateSubtask(message.data.cardId, message.data.checked);
  }

  handleRemoveSubtask(message: MsgRemoveSubtask) {
    this.removeSubtask(message.data.cardId, message.data.checked);
  }

  reloadBoard = () => {
    // when the current connection is settled, try again
    this.wsConnectionPromise = this.wsConnectionPromise.finally(this.connectWs);
  };

  toggleFavorite = () => {
    this.updateCtxBoard((prevBoard) => {
      return {
        ...prevBoard,
        favorite: !prevBoard.favorite,
      };
    });
  };

  addColumn = (column: ColumnDTO) => {
    this.updateCtxBoard((prevBoard) => addColumn(prevBoard, column));
  };

  deleteColumn = (id: string) => {
    this.updateCtxBoard((prevBoard) => removeColumn(prevBoard, id));
  };

  updateColumn = (id: string, data: UpdateColumnData) => {
    this.updateCtxBoard((prevBoard) => updateColumn(prevBoard, id, data));
  };

  removeCardsFromColumn = (columnId: string, cardIds: string[]) => {
    this.updateCtxBoard((prevBoard) =>
      removeCardsFromColumn(prevBoard, columnId, cardIds),
    );
  };

  moveColumn = (columnId: string, index: number) => {
    this.updateCtxBoard((prevBoard) => moveColumn(prevBoard, columnId, index));
  };

  addCard = (columnId: string, card: ICard) => {
    this.updateCtxBoard((prevBoard) => addCard(prevBoard, columnId, card));
  };

  restoreCard = (columnId: string, card: ArchivedCard) => {
    this.updateCtxBoard((prevBoard) => restoreCard(prevBoard, columnId, card));
  };

  deleteArchivedCard = (id: string) => {
    this.updateCtxBoard((prevBoard) => deleteArchivedCard(prevBoard, id));
  };

  updateCard = (cardId: string, data: UpdateCardData) => {
    this.updateCtxBoard((prevBoard) => updateCard(prevBoard, cardId, data));
  };

  assignMember = (cardId: string, id: string) => {
    this.updateCtxBoard((prevBoard) => assignMember(prevBoard, cardId, id));
  };

  unassignMember = (cardId: string, id: string) => {
    this.updateCtxBoard((prevBoard) => unassignMember(prevBoard, cardId, id));
  };

  assignTag = (cardId: string, id: string) => {
    this.updateCtxBoard((prevBoard) => assignTag(prevBoard, cardId, id));
  };

  unassignTag = (cardId: string, id: string) => {
    this.updateCtxBoard((prevBoard) => unassignTag(prevBoard, cardId, id));
  };

  addAttachment = (cardId: string) => {
    this.updateCtxBoard((prevBoard) => addAttachment(prevBoard, cardId));
  };

  removeAttachment = (cardId: string) => {
    this.updateCtxBoard((prevBoard) => removeAttachment(prevBoard, cardId));
  };

  addComment = (cardId: string) => {
    this.updateCtxBoard((prevBoard) => addComment(prevBoard, cardId));
  };

  deleteComment = (cardId: string) => {
    this.updateCtxBoard((prevBoard) => deleteComment(prevBoard, cardId));
  };

  moveCard = (
    cardId: string,
    srcColId: string,
    srcColIndex: number,
    dstColId: string,
    dstColIndex: number,
  ) => {
    // We're not using `prevState` here because we need to return the new index of the card.
    //
    // Since we're not using `prevState`, calling `moveCard` in the same JS event loop as another function which
    // changes the `boardContext.board` will omit the changes made by prior functions.
    //
    // TODO: Figure out a way to properly move the card while maintaining previous state changes
    const [board, newIndex] = moveCard(
      this.state.boardContext!.board,
      cardId,
      srcColId,
      srcColIndex,
      dstColId,
      dstColIndex,
    );
    this.updateCtxBoard((_) => board);
    return newIndex;
  };

  filterCards = (value: string | null, fields: CardFilterField[]) => {
    this.updateCtxBoard((prevBoard) => filterCards(prevBoard, value, fields));
  };

  addPriority = (priority: PriorityDTO) => {
    this.updateCtxBoard((prevBoard) => addPriority(prevBoard, priority));
  };
  updatePriority = (priorityId: string, data: UpdatePriorityData) => {
    this.updateCtxBoard((prevBoard) =>
      updatePriority(prevBoard, priorityId, data),
    );
  };
  movePriority = (priorityId: string, index: number) => {
    this.updateCtxBoard((prevBoard) =>
      movePriority(prevBoard, priorityId, index),
    );
  };
  removePriority = (priorityId: string) => {
    this.updateCtxBoard((prevBoard) => removePriority(prevBoard, priorityId));
  };

  addTag = (item: TagDTO) => {
    this.updateCtxBoard((prevBoard) => addTag(prevBoard, item));
  };
  updateTag = (id: string, data: UpdateTagData) => {
    this.updateCtxBoard((prevBoard) => updateTag(prevBoard, id, data));
  };
  removeTag = (id: string) => {
    this.updateCtxBoard((prevBoard) => removeTag(prevBoard, id));
  };

  addSubtask = (cardId: string) => {
    this.updateCtxBoard((prevBoard) => addSubtask(prevBoard, cardId));
  };

  updateSubtask = (cardId: string, checked: boolean) => {
    this.updateCtxBoard((prevBoard) =>
      updateSubtask(prevBoard, cardId, checked),
    );
  };

  removeSubtask = (cardId: string, checked: boolean) => {
    this.updateCtxBoard((prevBoard) =>
      removeSubtask(prevBoard, cardId, checked),
    );
  };

  addMember = (item: MemberDTO | InviteeDTO) => {
    this.updateCtxBoard((prevBoard) => addMember(prevBoard, item));
  };
  updateMember = (id: string, data: UpdateMemberData) => {
    this.updateCtxBoard((prevBoard) => updateMember(prevBoard, id, data));
  };
  removeMember = (id: string) => {
    this.updateCtxBoard((prevBoard) => removeMember(prevBoard, id));
  };
  updateBoardSettings = (newBoardSettings: UpdateBoardSettingsData) => {
    this.updateCtxBoard((prevBoard) =>
      updateBoardSettings(prevBoard, newBoardSettings),
    );
  };
  setOwner = (id: string) => {
    this.updateCtxBoard((prevBoard) => changeBoardOwner(prevBoard, id));
  };
  updateInvitee = (id: string) => {
    this.updateCtxBoard((prevBoard) => updateInvitee(prevBoard, id));
  };

  updateCtxBoard = (fn: (board: IBoard) => IBoard) => {
    this.setState((prevState) => {
      return {
        boardContext: {
          ...prevState.boardContext!,
          board: fn(prevState.boardContext!.board),
        },
      };
    });
  };

  updateCtx = (fn: (board: IBoardContext) => Partial<IBoardContext>) => {
    this.setState((prevState) => {
      return {
        boardContext: {
          ...prevState.boardContext!,
          ...fn(prevState.boardContext!),
        },
      };
    });
  };

  setLoadingMemberIds = (ids: string[]) => {
    this.updateCtx((_) => {
      return {
        loadingMemberIds: ids,
      };
    });
  };

  setLoadingTagIds = (ids: string[]) => {
    this.updateCtx((_) => {
      return {
        loadingTagIds: ids,
      };
    });
  };

  setActiveBoardMembersStatus = (ids: string[]) => {
    this.updateCtx((_) => {
      return {
        activeBoardMembersStatus: ids,
      };
    });
  };

  async loadBoard() {
    try {
      const data = await getAccess(this.props.match.params.routeBoardId);

      this.setState({ isOwned: data.owned });

      if (data.archived) {
        this.setState({
          isArchived: true,
          notFound: true,
          isLoading: false,
        });

        if (data.teamId) {
          this.setState({
            archivedBoardsLink: `/`,
            isPersonal: false,
          });
        }
      }
    } catch (err) {
      console.debug(err);

      if (this.ws) {
        this.closeWs(this.ws);
      }
      this.setState({
        isLoading: false,
      });
    }
  }

  setArchived = () => {
    // board
    this.loadBoard();
  };

  setArchivedCards = (cards: ArchivedCard[] | null) => {
    this.setState((prevState) => {
      return {
        boardContext: {
          ...prevState.boardContext!,
          board: {
            ...prevState.boardContext!.board,
            archivedCards: cards,
          },
        },
      };
    });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="card-board-component">
          <span className="loader mt-md flex-h-center-self text-2xl"></span>
        </div>
      );
    }

    if (this.state.isArchived) {
      return (
        <PageMessage>
          {this.state.isOwned ? (
            <>
              <h1 className="primary-title normalcase pb-xs">Archived Board</h1>
              <br />
              <p>
                You are trying to access an archived resource. You may restore
                the board from "Archived boards" found in the three dot menu on
                your homepage.
              </p>
              <ul className="control-list-component flex-h-center">
                <li>
                  <LinkButton
                    to={`${this.state.archivedBoardsLink}`}
                    className="secondary-button"
                  >
                    Homepage
                  </LinkButton>
                </li>
              </ul>
            </>
          ) : (
            <>
              <h1 className="primary-title normalcase pb-xs">Archived Board</h1>
              <br />
              <p>
                You are trying to access an archived resource. The team owner
                can restore the board from "Archived Boards" found in the three
                dot menu on their homepage.
              </p>
              <ul className="control-list-component flex-h-center">
                <li>
                  <LinkButton
                    to="/"
                    className="secondary-button"
                  >
                    <span className="fas fa-exclamation-circle accent-text-red icon"></span>{' '}
                    <span>Homepage</span>
                  </LinkButton>
                </li>
              </ul>
            </>
          )}
        </PageMessage>
      );
    }

    if (this.state.userRemovedFromBoard || this.state.notFound) {
      return (
        <PageMessage>
          <h1 className="primary-title normalcase pb-xs">Board Not Found</h1>
          <br />
          <p>The board you are trying to access could not be found.</p>
          <ul className="control-list-component flex-h-center">
            <li>
              <LinkButton
                to="/"
                className="secondary-button"
              >
                Homepage
              </LinkButton>
            </li>
          </ul>
        </PageMessage>
      );
    }

    if (!this.state.boardContext) {
      return null;
    }

    return (
      <BoardContext.Provider value={this.state.boardContext}>
        <>
          {this.props.children}
          <ConnectionNotification
            connectionStatus={this.state.connectionStatus}
          />
        </>
      </BoardContext.Provider>
    );
  }
}

BoardProvider.contextType = AppContext;
export default BoardProvider;
