import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend'; // Import the http backend plugin

// Assuming you're storing the user's language preference in localStorage
const storedLanguage = localStorage.getItem('language') || 'en_US';

// Used for testing purposes
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const customHttpBackend = new HttpBackend(null, {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
  request: async (options, url, payload, callback) => {
    // Simulate network delay
    await delay(0);
    // Default loading mechanism
    fetch(url, options)
      .then((response) => {
        response.text().then((data) => {
          callback(null, {
            status: response.status,
            data,
          });
        });
      })
      .catch((error) => {
        callback(error, { status: 'failed' });
      });
  },
});

i18n
  // Testing purposes
  // .use(customHttpBackend) // Use the http backend to load translations
  .use(HttpBackend) // Use the http backend to load translations
  .use(initReactI18next) // Pass the i18n instance to react-i18next.
  .init({
    // Define the languages that your application supports
    supportedLngs: ['en_US', 'ro_RO'],
    debug: false,
    // The language to use if translations in user language are not available
    fallbackLng: 'en_US',
    // The default language to use
    lng: storedLanguage,
    // Specify namespaces that your application should preload
    ns: [
      'account',
      'accountGeneral',
      'globalScope',
      'addCardComment',
      'addCardDescription',
      'app',
      'avatarGenerator',
      'boardCardFlyout',
      'boardArchivedCardFlyout',
      'boardCardFlyoutTemplate',
      'boardColumnExpanded',
      'boardColumnPlaceholder',
      'boardListingCard',
      'boardOperationsMenu',
      'boardTopbar',
      'cardActivity',
      'columnContext',
      'columnMenu',
      'deviceOverrides',
      'fileInput',
      'home',
      'leaveOperationsMenu',
      'legalFooter',
      'legalMenu',
      'helpDialog',
      'welcomeDialog',
      'login',
      'memberMenu',
      'memberSearchMenu',
      'membership',
      'newMenu',
      'passwordForm',
      'priorityContext',
      'priorityDetailMenu',
      'boardArchive',
      'priorityForm',
      'priorityMenu',
      'priorityOperationsMenu',
      'registration',
      'removeMemberMenu',
      'removeTagMenu',
      'appSettings',
      'subscriptions',
      'tagContext',
      'tagDetailMenu',
      'tagForm',
      'tagOperationsMenu',
      'userMenu',
      'boardCardPlaceholder',
      'userComment',
      'boardCardMembers',
      'columnArchive',
      'columnDelete',
      'columnLimit',
      'boardColumnCollapsed',
      'cardFilterMenu',
      'boardCardDescription',
      'cardDataContext',
      'cardDataMenu',
      'memberManageMenu',
      'imageViewer',
      'imageZoomer',
      'archivedCardsPage',
      'cardSubtask',
      'boardSettings',
      'appearanceTab',
      'teamSettings',
      'teamGeneral',
      'teamMembers',
      'teamPlan',
      'teamPlanRow',
      'boardCardArchived',
      'manageTeamMember',
      'teamMembership',
      'teamHousekeeping',
      'stepIntro',
      'stepSpace',
      'stepBoard',
      'stepColumns',
      'stepTasks',
      'stepMembers',
      'stepSummary',
    ],
    defaultNS: 'app',
    // Backend plugin options
    backend: {
      // Path to your translation files
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    // React already does escaping
    interpolation: {
      escapeValue: false,
    },
    // Function to call when a missing translation key is encountered
    parseMissingKeyHandler: (missingKey) => `[missing:${missingKey}]`,
    // Opt into sending the lng & ns query parameters to your server
    sendMissingTo: 'fallback', // 'current' | 'all' | 'fallback'
    // This option allows passing the namespaces needed for rendering this specific content
    nsMode: 'fallback', // default 'fallback', options are 'fallback', 'strict', or 'default'
  });

export default i18n;
