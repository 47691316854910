import React, { Component, RefObject } from 'react';
import { RouteComponentProps } from 'react-router';
import { handleClickOutside } from '../../../common/helpers/handleClickOutside';
import { TContextAlignment } from '../../../common/types/ContextAlignment';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { relativeParentOffset } from '../../../common/helpers/relativeParentOffset';
import ReactDOM from 'react-dom';
import AppContext from '../../../common/contexts/AppContext';

interface Props extends Partial<RouteComponentProps> {
  context?: TContextMenu; //we use this to know if the children has changed
  contextId?: string; //we use this to know if a context menu from within a card is opened
  triggerContent: JSX.Element;
  triggerClassDefault?: string;
  triggerClassActive?: string;
  contextMenuClassName?: TContextAlignment;
  contextMenuStyles?: React.CSSProperties;
  nextOptionText?: string;
  previousOptionText?: string;
  isDisabled?: boolean;
  resetSelectedContext?: () => void;
  setShowBlockOptionsDropDown?: (val: any) => void; // used only for lexical toolbar to close the context menu when clicking on format button
  showBlockOptionsDropDown?: boolean; // used only for lexical toolbar to close the context menu when clicking on format button
  rightClickTrigger?: boolean;
  title?: string;
  name?: string;
  forceLTR?: boolean;
  dept: number;
  setFocusableItem?: (element: HTMLElement) => void;
}

interface State {
  isOpen: boolean;
  transformStyle: React.CSSProperties | null;
  positionStyle: React.CSSProperties | null;
  dropUp: boolean;
  direction: null | 'forwards' | 'backwards';
}

class ContextMenu extends Component<Props, State> {
  contextButtonRef: React.RefObject<HTMLButtonElement | HTMLDivElement>;
  contextMenuRef: React.RefObject<HTMLUListElement>;
  constructor(props: Props) {
    super(props);
    this.contextButtonRef = React.createRef();
    this.contextMenuRef = React.createRef();
    this.state = {
      isOpen: false,
      transformStyle: null,
      positionStyle: null,
      dropUp: false,
      direction: null,
    };
  }

  setDirection = (direction: null | 'forwards' | 'backwards') => {
    this.setState({
      direction,
    });
  };

  updateMenuState = (e?: React.MouseEvent, menuState?: string) => {
    let newState: boolean | undefined = menuState
      ? menuState === 'open'
        ? true
        : false
      : undefined;

    if (typeof newState === 'undefined' || newState === false) {
      if (this.props.resetSelectedContext) {
        this.props.resetSelectedContext();
      }
    }

    this.setState((prevState: State) => {
      return {
        isOpen: typeof newState !== 'undefined' ? newState : !prevState.isOpen,
      };
    });
    if (e) {
      e.stopPropagation();
    }
  };

  handleClose() {
    this.updateMenuState(undefined, 'close');
  }

  handleOutsideClose(ev: any) {
    if (document.querySelector('.dropdown-structure .dropdown-options')) {
      // prevent to close the context menu if the select is open "inside" the context menu
      return;
    }

    if (document.getElementsByClassName('dialog-component').length) {
      ev.stopPropagation();
    } else if (!this.contextButtonRef.current?.contains(ev.target)) {
      // prevent to open the menu again if click outside of the menu AND on trigger button
      handleClickOutside(ev, () => this.handleClose(), this.contextMenuRef);
      ev.stopPropagation();
    }
  }

  private handleScrollClose = (e: Event) => {
    const target = e.target as HTMLElement;
    if (!this.state.isOpen) return;
    if (
      target.classList?.contains('bar-content') &&
      target.contains(this.contextButtonRef.current)
    ) {
      handleClickOutside(e, () => this.handleClose(), this.contextButtonRef);
    }

    if (target && !target.classList) {
      if (!this.contextButtonRef.current?.closest('.bar-content')) {
        handleClickOutside(e, () => this.handleClose(), this.contextButtonRef);
      }
    }

    if (
      target &&
      target.classList?.contains('scroll-content') &&
      !this.contextButtonRef.current?.closest('.bar-content')
    ) {
      handleClickOutside(e, () => this.handleClose(), this.contextButtonRef);
    }
  };

  handleContextButtonKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Enter') {
      const menuItems = this.contextMenuRef.current
        ? this.contextMenuRef.current.querySelectorAll(
            'button:not([disabled]), [href], input, select, textarea',
          )
        : [];

      const menuItemsArray = Array.from(menuItems);
      let focusableItemIndex;
      if (menuItemsArray.length > 3) {
        focusableItemIndex =
          menuItemsArray.indexOf(document.activeElement!) - 2;
      } else {
        focusableItemIndex =
          menuItemsArray.indexOf(document.activeElement!) - 1;
      }

      if (this.props.setFocusableItem) {
        this.props.setFocusableItem(
          menuItemsArray[focusableItemIndex] as HTMLElement,
        );
      }
    }
    // TRAP FOCUS
    if (e.key === 'Tab') {
      const menuItems = this.contextMenuRef.current
        ? this.contextMenuRef.current.querySelectorAll(
            'button:not([disabled]), [href], input, select, textarea',
          )
        : [];
      const contextToggleButton = this.contextButtonRef.current;
      const firstMenuItem = menuItems[0] as HTMLElement;
      const lastMenuItem = menuItems[menuItems.length - 1] as HTMLElement;

      if (!e.shiftKey && document.activeElement === contextToggleButton) {
        e.preventDefault();
        firstMenuItem.focus();
      } else if (e.shiftKey && document.activeElement === contextToggleButton) {
        e.preventDefault();
        lastMenuItem.focus();
      } else if (
        contextToggleButton &&
        e.shiftKey &&
        document.activeElement === firstMenuItem
      ) {
        e.preventDefault();
        contextToggleButton.focus();
      } else if (
        contextToggleButton &&
        !e.shiftKey &&
        document.activeElement === lastMenuItem
      ) {
        e.preventDefault();
        contextToggleButton.focus();
      }
      // CLOSE ON ESCAPE
    } else if (
      e.key === 'Escape' &&
      !document.getElementsByClassName('dialog-component').length
    ) {
      this.contextButtonRef.current?.focus();
      this.handleClose();
    }
  };

  previousElementFocus = () => {
    // focus on first element when nested context is opened
    if (
      this.contextMenuRef &&
      document.activeElement !== this.contextButtonRef.current
    ) {
      const directionalButton = document.querySelector(
        this.context.directionalButtonId,
      ) as HTMLElement;
      if (directionalButton) {
        // when going backwards, focus previous element
        directionalButton.focus();
      } else {
        // when going forwards, focus first element
        const firstFocusableElement =
          this.contextMenuRef.current?.querySelector(
            'button:not([disabled]), [href], input, select, textarea',
          ) as HTMLElement;
        if (firstFocusableElement) {
          if (!this.props.rightClickTrigger) {
            firstFocusableElement.focus();
          }
        }
      }
    }
  };

  computeFixedPosition = () => {
    if (!this.contextButtonRef.current) return;

    const contextButtonRect =
      this.contextButtonRef.current.getBoundingClientRect();
    const borderOffset = 1;
    let buttonHeight = this.contextButtonRef.current.clientHeight;
    let buttonWidth = this.contextButtonRef.current.offsetWidth;
    // we take in account that we have to see the menu AND the button borders (top / bottom) at the same time
    buttonHeight += borderOffset;

    this.setState(
      {
        positionStyle: {
          right: 'auto',
          left: contextButtonRect.left,
          top: contextButtonRect.top + buttonHeight,
        },
      },
      () => {
        // we put this in the callback because we need to make sure we have the correct left & top
        if (!this.contextMenuRef.current || !this.contextButtonRef.current)
          return;
        const contextButtonRect =
          this.contextButtonRef.current.getBoundingClientRect();
        let translateY = 'translateY(0)';
        let translateX = `translateX(calc(-100% + ${buttonWidth}px))`;
        let right = 'auto';
        let left = contextButtonRect.left + 'px';
        let isDropUp = false;

        if (
          contextButtonRect.left < window.innerWidth / 2 ||
          this.props.forceLTR === true
        ) {
          translateX = `translateX(${borderOffset}px)`;
        } else {
          translateX = `translateX(0)`;
          left = 'auto';
          right =
            window.innerWidth -
            contextButtonRect.left -
            contextButtonRect.width +
            'px';
        }

        /*TODO: Consider removing this unless we hit odd cases again,
          the code for when contextButtonRect.left >
          window.innerWidth / 2 takes care of the issue
        */
        // if (relativeParentOffset(this.contextButtonRef.current!)) {
        //   translateX = `translateX(0)`;
        // }

        if (contextButtonRect.top > window.innerHeight / 2) {
          translateY = `translateY(calc(-100% - ${buttonHeight}px))`;
          isDropUp = true;
        }

        this.setState({
          positionStyle: {
            ...this.state.positionStyle,
            left,
            right,
          },
          transformStyle: {
            transform: translateX + ' ' + translateY,
          },
          dropUp: isDropUp,
        });
      },
    );
  };

  firstElementFocus = () => {
    const menuItems = this.contextMenuRef.current
      ? this.contextMenuRef.current.querySelectorAll(
          'button:not([disabled]), [href], input, select, textarea',
        )
      : [];
    const directionalButton = document.querySelector(
      this.context.directionalButtonId,
    ) as HTMLElement;
    const firstMenuItem = menuItems[0] as HTMLElement;

    if (firstMenuItem && !directionalButton) {
      (menuItems[0] as HTMLElement).focus();
    }
  };

  private onMouseUp = (ev: MouseEvent) => {
    if (!this.state.isOpen) return;
    handleClickOutside(
      ev,
      () => this.handleOutsideClose(ev),
      this.contextMenuRef,
    );
  };

  handleOpenSearch = (e: KeyboardEvent) => {
    if (e.key === 'F3' || (e.ctrlKey && (e.key === 'f' || e.key === 'F'))) {
      e.preventDefault();
      this.updateMenuState(undefined, 'open');
    }
  };

  handleOpenCustomizer = (e: KeyboardEvent) => {
    if (e.ctrlKey && (e.key === 'g' || e.key === 'G')) {
      e.preventDefault();
      this.updateMenuState(undefined, 'open');
    }
  };

  handleTouchClose = (e: any) => {
    const modalElement = document.getElementById('context-menu-id');
    if (modalElement && modalElement.contains(e.target)) {
      //Touch inside should block body scroll
      document.body.style.overflow = 'hidden';
    } else {
      //Touch outside should allow body scroll
      document.body.style.overflow = '';
    }
  };

  componentDidMount() {
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('touchstart', this.handleTouchClose, true);
    document.addEventListener('scroll', this.handleScrollClose, true);
    if (this.props.name === 'filterMenu') {
      document.addEventListener('keydown', this.handleOpenSearch);
    }

    if (this.props.name === 'customizerMenu') {
      document.addEventListener('keydown', this.handleOpenCustomizer);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('touchstart', this.handleTouchClose, true);
    document.removeEventListener('scroll', this.handleScrollClose, true);
    if (this.props.name === 'filterMenu') {
      document.removeEventListener('keydown', this.handleOpenSearch);
    }
    if (this.props.name === 'customizerMenu') {
      document.removeEventListener('keydown', this.handleOpenCustomizer);
    }
    this.setState({
      positionStyle: null,
      transformStyle: null,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // Preserve the scroll position before re-rendering
    const scrollTop = this.preserveScrollPosition();

    if (prevProps.location?.key !== this.props.location?.key) {
      this.handleClose();
    }

    // After re-render, restore scroll position
    if (this.contextMenuRef.current && prevState.isOpen !== this.state.isOpen) {
      this.restoreScrollPosition(scrollTop);
    }

    if (
      prevProps.dept !== this.props.dept ||
      prevState.isOpen !== this.state.isOpen ||
      prevProps.context !== this.props.context
    ) {
      if (this.state.isOpen) {
        if (this.props.dept > prevProps.dept) {
          this.setDirection('forwards');
        } else if (this.props.dept < prevProps.dept) {
          this.setDirection('backwards');
        } else {
          this.setDirection(null);
        }

        this.computeFixedPosition();
        this.previousElementFocus();
        document.addEventListener('keydown', this.handleKeyDown);
        this.firstElementFocus();
      } else {
        document.removeEventListener('keydown', this.handleKeyDown);
        this.setState({
          positionStyle: null,
          transformStyle: null,
        });
        this.context.setDirectionalButtonId(null);
      }
    }

    // used only for lexical toolbar
    if (
      prevProps.showBlockOptionsDropDown !==
        this.props.showBlockOptionsDropDown &&
      !this.props.showBlockOptionsDropDown
    ) {
      this.handleClose();
    }
  }

  preserveScrollPosition = () => {
    if (this.contextMenuRef.current) {
      return this.contextMenuRef.current.scrollTop;
    }
    return 0;
  };

  restoreScrollPosition = (scrollTop: number) => {
    if (this.contextMenuRef.current) {
      this.contextMenuRef.current.scrollTop = scrollTop;
    }
  };

  preventDefaultContextMenu = (e: any) => {
    if (this.props.rightClickTrigger && !this.props.isDisabled) {
      e.preventDefault();
      this.updateMenuState(e, 'open');
    }
  };

  render() {
    return (
      <>
        {this.props.rightClickTrigger ? (
          <div
            onContextMenu={this.preventDefaultContextMenu}
            title={this.state.isOpen ? '' : this.props.title}
            data-title={this.state.isOpen ? '' : this.props.title}
            ref={this.contextButtonRef as RefObject<HTMLDivElement>}
          >
            {this.props.triggerContent}
          </div>
        ) : (
          <button
            disabled={this.props.isDisabled}
            title={this.state.isOpen ? '' : this.props.title}
            data-title={this.state.isOpen ? '' : this.props.title}
            className={[
              this.props.triggerClassDefault
                ? this.state.isOpen
                  ? this.props.triggerClassActive
                    ? this.props.triggerClassActive
                    : this.props.triggerClassDefault
                  : this.props.triggerClassDefault
                : '',
            ].join(' ')}
            onClick={(e) => {
              if (this.props.rightClickTrigger) {
                e.stopPropagation();
                return;
              }
              this.updateMenuState(e);

              // used only for lexical toolbar
              if (this.props.setShowBlockOptionsDropDown) {
                this.props.setShowBlockOptionsDropDown(
                  !this.props.showBlockOptionsDropDown,
                );
              }
            }}
            onKeyDown={this.handleContextButtonKeyDown}
            ref={this.contextButtonRef as RefObject<HTMLButtonElement>}
          >
            <div className="pe-none">{this.props.triggerContent}</div>
          </button>
        )}
        {this.state.isOpen &&
          ReactDOM.createPortal(
            <ul
              data-context-id={this.props.contextId}
              id="context-menu-id"
              className={`context-menu-component open${
                this.state.dropUp ? ' dropup' : ''
              } ${
                this.state.direction == 'forwards'
                  ? 'slide-to-left'
                  : this.state.direction == 'backwards'
                  ? 'slide-to-right'
                  : ''
              }`}
              style={{
                ...this.props.contextMenuStyles,
                ...this.state.transformStyle,
                ...this.state.positionStyle,
              }}
              ref={this.contextMenuRef}
            >
              {this.props.children}
            </ul>,
            document.querySelector('.context-menu-structure')!,
          )}
      </>
    );
  }
}

ContextMenu.contextType = AppContext;

export default ContextMenu;
